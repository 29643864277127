import React, { Component , Fragment, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import BadgeSlider from "../../components/BadgeSlider";
import Appfooter from "../../components/Appfooter";
import { GRAPHQL_MAX_RESULTS_LIMIT } from "../../config";

const GET_EMPLOYEE_QUERY = gql`
    query GetEmployee($fetchEmployee: Boolean! $id: String!) {
        EmployeeList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: {isActive: {_eq: true} }, orderBy: { name: ASC }) {
            id
            name
            jobTitle
            user {
                id
                picture
            }
        }
        EmployeeById(id: $id) @include(if: $fetchEmployee)  {
            id
            name
            birthdate
            email
            jobTitle
            user {
                picture
            }
        }
        BadgeList(filter: { isActive: { _eq: true } }, orderBy: { name: ASC }) {
            id
            name
            description
            thumbnail
            points
        }
    }
`;

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            employee {
                id
            }
        }
    }
`;

const UPSERT_BADGE_ASSIGNMENT_MUTATION = gql`
    mutation UpsertBadgeAssignment($badgeId: UUID!, $senderId: UUID!, $receiverId: UUID!, $message: String) {
        upsert_BadgeAssignment(badgeAssignment: { badge: { id: $badgeId }, sender: { id: $senderId }, receiver: { id: $receiverId }, message: $message }) {
            id
        }
    }
`;

function GiveBadge() {

    const navigate = useNavigate();

    const { employeeId } = useParams();

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors } = useForm();

    const [selectedBadgeId, setSelectedBadgeId] = useState(null);

    const [upsertBadgeAssignmentMutation, { upsertBadgeAssignmentMutationData }] = useMutation(UPSERT_BADGE_ASSIGNMENT_MUTATION);

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { loading, error, data } = useQuery(GET_EMPLOYEE_QUERY, { variables: { id: employeeId || "00000000-0000-0000-0000-000000000000", fetchEmployee: employeeId !== null }});

    const giveBadge = async (formData) => {
        if(!selectedBadgeId) {
            setError('badgeId', { type: 'custom' })
            return;
        } else {
            clearErrors('badgeId')
        }

        if(!viewerData?.Viewer?.employee?.id) {
            toast.success('Não tem permissões para enviar aplausos!');
        }
        
        const upsertResult = await upsertBadgeAssignmentMutation({ 
            variables: {
                badgeId: selectedBadgeId,
                senderId: viewerData.Viewer.employee.id,
                receiverId: employeeId || formData.receiverId,
                message: formData.message,
            },
        });

        if(upsertResult.data) {
            toast.success('Aplauso enviado!');
            navigate(`/employees/${employeeId || formData.receiverId}`)
        }
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <Header />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">

                { (loading || viewerData) && <LoadingOverlayWrapper /> }
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Dar Aplauso</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(giveBadge)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-gorup">
                                                    <label className="mont-font fw-600 font-xssss">Colaborador</label>
                                                    { employeeId && <input type="text" className="form-control" defaultValue={data?.EmployeeById?.name}  {...register("receiverId")} readOnly /> }
                                                    { !employeeId && data?.EmployeeList &&
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("receiverId", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            data && data.EmployeeList && data.EmployeeList?.filter(e => viewerData && e.id !== viewerData?.Viewer?.employee?.id)
                                                            .map((employee) => {
                                                                return (
                                                                    <option key={employee.id} value={employee.id}>{employee.name} {employee.jobTitle && `(${employee.jobTitle})`}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    }
                                                    {errors.receiverId && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>        
                                            </div>

                                            <div className="col-lg-12 mb-3">
                                                <div className="form-gorup">
                                                    <label className="mont-font fw-600 font-xssss">Aplauso</label>
                                                    { false && <input type="text" name="comment-name" className="form-control" /> }
                                                    { false && 
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("badgeId", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            data && data.BadgeList && data.BadgeList
                                                            .map((badge) => {
                                                                return (
                                                                    <option key={badge.id} value={badge.id}>{badge.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    }
                                                </div>        
                                            </div>

                                            <BadgeSlider badges={(data && data.BadgeList) || []} onChange={ (id) => {setSelectedBadgeId(id); id && clearErrors('badgeId')} } />
                                            {errors.badgeId && <span className="text-red">Selecione um aplauso</span>}
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-lg-12 mt-3 mb-0 mont-font fw-600 font-xsss text-success">
                                                {data?.BadgeList?.filter(e => e.id === selectedBadgeId)[0]?.description}
                                            </div>                                     
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mt-3 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xssss">Mensagem</label>
                                                    <textarea name="comment-name" className="form-control h100 theme-dark-bg lh-24" rows={5} {...register("message")} />
                                                </div>        
                                            </div>                                     
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(giveBadge)}>Enviar</a>
                                            </div>
                                        </div>

                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter/>
        </div>

        </Fragment>
    );
}

export default GiveBadge;
import React, { Component, useState } from 'react';
import { Fragment } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';
import { RBACWrapper } from "react-simple-rbac";
import { toast } from "react-toastify";

import { Modal } from "react-bootstrap";

import { getAttachmentUrl, getImageUrl, getProfilePicture, HASHTAG_FORMATTER } from '../utils';

import Comments from './comments/Comments';
import Gallery from './Gallery';
import SurveyView from './SurveyView';
import { InView } from 'react-intersection-observer';
import { useTracking } from 'react-tracking';
import PostReactions from './PostReactions';
import EditPostModal from './EditPostModal';

const DELETE_POST_MUTATION = gql`
    mutation DeletePost($id: String!) {
        delete_Post(id: $id)
    }
`;

const UPSERT_POST_PIN_MUTATION = gql`
    mutation upsert_Post($id: UUID!, $isPinned: Boolean!) {
        upsert_Post(post: { id: $id, isPinned: $isPinned }) {
            id
        }
    }
`;

const DefaultPostContent = ({ title, content, postImage, postVideo, attachments, employee, galleryId }) => (
    <Fragment>
        <div className="card-body p-0 me-lg-5">
            {employee && <p className="fw-700 text-grey-500 lh-26 font-xssss w-100 mb-0"><Link to={`/employees/${employee.id}`} className="fw-700 text-success">@{employee.name}</Link></p>}
            {title && <p className="fw-700 lh-26 font-xsss w-100 mb-0">{title}</p>}
            <div className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-0 mb-2" /*dangerouslySetInnerHTML={{ __html: HASHTAG_FORMATTER(des) }}*/>{ HASHTAG_FORMATTER(content) }</div>
            {false && <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2"><a href="/defaultvideo" className="fw-600 text-primary">See more</a></p>}
        </div>
        
        { attachments && attachments.length > 0 &&
        <div className="card-body p-0 mt-1 mb-3">
            <p className="fw-700 lh-26 font-xssss w-100 mb-0">Anexos</p>
            { attachments.map((attachment) => (
                <a key={attachment.id} href={getAttachmentUrl(attachment.file)} className="d-block font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-xss text-grey-500 feather-paperclip me-2"></i><span>{ attachment.name || attachment.file }</span></a>
            ))}                
        </div>
        }
        
        {postImage ?
        <div className="card-body d-block p-0 mb-3">
            <div className="row ps-2 pe-2">
                <div className="col-sm-12 p-1"><img src={postImage} className="rounded-3 w-100" alt="" /></div>                                        
            </div>
        </div>
        : ''}
        {postVideo ?  
        <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
            <a href="#" className="video-btn">
                <video autoPlay loop className="float-right w-100">
                    <source src={postVideo} type="video/mp4" />
                </video>
            </a>
        </div>
        : ''}

        <Gallery id={galleryId} maxImages={6} />
    </Fragment>
);

const BadgeAssignmentPostContent = ({ title, content, postImage, postVideo, attachments, employee }) => (
    <Fragment>
        <div className="card w-100 rounded-xxl overflow-hidden border-0 mb-0 mt-0 pb-0">
            <div className="card-body position-relative h40 bg-image-cover bg-image-center"></div>
            <div className="card-body d-flex pt-4 pb-0 text-center">
                <figure className="avatar mt--6 mb-0 z-index-1 w200 z-index-1 ms-auto me-auto"><img src={postImage} alt={title} className="p-1 mb-1 bg-white rounded-xl w-100 aspect-ratio-1" /></figure>
            </div>
            
            <div className="card-body d-block p-0 text-center">
                <h4 className="font-xs ls-1 fw-700 text-grey-900">{title} <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500"><Link to={`/employees/${employee.id}`} className="fw-700 text-success">@{employee.name}</Link></span></h4>
            </div>
        </div>
            
        <div className="card-body p-0 me-lg-5">
            {false && employee && <p className="fw-700 text-grey-500 lh-26 font-xssss w-100 mb-0"><Link to={`/employees/${employee.id}`} className="fw-700 text-success">@{employee.name}</Link></p>}
            {false && title && <p className="fw-700 lh-26 font-xssss w-100 mb-0">Aplauso {title}</p>}
            <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-0 mb-2" /*dangerouslySetInnerHTML={{ __html: HASHTAG_FORMATTER(des) }}*/>{ HASHTAG_FORMATTER(content) }</p>
            {false && <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2"><a href="/defaultvideo" className="fw-600 text-primary">See more</a></p>}
        </div>
        
        { attachments && attachments.length > 0 &&
        <div className="card-body p-0 mt-1 mb-3">
            <p className="fw-700 lh-26 font-xssss w-100 mb-0">Anexos</p>
            { attachments.map((attachment) => (
                <a key={attachment.id} href={getAttachmentUrl(attachment.file)} className="d-block font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-xss text-grey-500 feather-paperclip me-2"></i><span>{ attachment.name || attachment.file }</span></a>
            ))}                
        </div>
        }
    </Fragment>
);

const EmployeeEntryPostContent = ({ title, content, postImage, postVideo, attachments, employee }) => (
    <Fragment>
        <div className="card-body p-0">
            {title && <p className="fw-700 lh-26 font-xsss w-100 mb-0">{title}</p>}
        </div>

        <div className="card w-100 shadow-xss rounded-xxl overflow-hidden border-0 mb-4 mt-3 pb-3">
            <div className="card-body position-relative h150 bg-image-cover bg-image-center"
            style={{backgroundImage: `url("/assets/images/bg/employee-loudspeaker.jpg")`}}></div>
            <div className="card-body d-block pt-4 text-center">
                <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto"><img src={getProfilePicture(employee, "/assets/images/user.png")} alt={employee.name} className="p-1 bg-white rounded-xl w-100" /></figure>
                <h4 className="font-xs ls-1 fw-700 text-grey-900">
                    <Link to={`/employees/${employee.id}`} className="fw-700 text-success">{employee.name}</Link>
                    <span className="d-block font-xsss fw-500 mt-1 mb-3 lh-3 text-grey-500"><i className="feather-briefcase me-1"></i> { employee.jobTitle }</span>
                    <Link to={`/employees/orgChart?employeeId=${employee.id}`} className="fw-500 text-success font-xsss"><i className="feather-users me-1"></i> Ver no Organograma</Link>
                </h4>
            </div>
        </div>

        <div className="card-body p-0">
            <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-0 mb-2" /*dangerouslySetInnerHTML={{ __html: HASHTAG_FORMATTER(des) }}*/>{ HASHTAG_FORMATTER(content) }</p>
        </div>
        
        { attachments && attachments.length > 0 &&
        <div className="card-body p-0 mt-1 mb-3">
            <p className="fw-700 lh-26 font-xssss w-100 mb-0">Anexos</p>
            { attachments.map((attachment) => (
                <a key={attachment.id} href={getAttachmentUrl(attachment.file)} className="d-block font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-xss text-grey-500 feather-paperclip me-2"></i><span>{ attachment.name || attachment.file }</span></a>
            ))}                
        </div>
        }
    </Fragment>
);

const BirthdayPostContent = ({ title, content, postImage, postVideo, attachments, employee }) => (
    <Fragment>
        <div className="card-body p-0">
            {title && <p className="fw-700 lh-26 font-xsss w-100 mb-0">{title}</p>}
        </div>

        <div className="card w-100 shadow-xss rounded-xxl overflow-hidden border-0 mb-4 mt-3">
            <div className="card-body position-relative h150 bg-image-cover bg-image-center"
            style={{backgroundImage: `url("/assets/images/bg/birthday.jpg")`}}></div>
            <div className="card-body d-block pt-4 text-center">
                <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto"><img src={getProfilePicture(employee, "/assets/images/user.png")} alt={employee.name} className="p-1 bg-white rounded-xl w-100 aspect-ratio-1" /></figure>
                <h4 className="font-xs ls-1 fw-700 text-grey-900">
                    <Link to={`/employees/${employee.id}`} className="fw-700 text-success">{employee.name}</Link>
                    { (employee.department?.name || employee.jobTitle) && <span className="d-block font-xss fw-500 mt-1 lh-3 text-grey-500 mb-3"><i className="feather-briefcase me-1"></i> { employee.department?.name } {employee.department?.name && employee.jobTitle && " - "} { employee.jobTitle }</span> }
                    <span className="d-block font-xs fw-700 mt-1 lh-3 text-grey-500"><i className="feather-calendar me-1"></i> <Moment format="DD/MM">{ employee.birthdate }</Moment></span>
                </h4>
            </div>
        </div>

        <div className="card-body p-0 mb-4">
            <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-0 mb-2" /*dangerouslySetInnerHTML={{ __html: HASHTAG_FORMATTER(des) }}*/>{ HASHTAG_FORMATTER(content) }</p>
        </div>
        
        { attachments && attachments.length > 0 &&
        <div className="card-body p-0 mt-1 mb-3">
            <p className="fw-700 lh-26 font-xssss w-100 mb-0">Anexos</p>
            { attachments.map((attachment) => (
                <a key={attachment.id} href={getAttachmentUrl(attachment.file)} className="d-block font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-xss text-grey-500 feather-paperclip me-2"></i><span>{ attachment.name || attachment.file }</span></a>
            ))}                
        </div>
        }
    </Fragment>
);

const BirthdaySummaryPostContent = ({ title, content, postImage, postVideo, attachments, employee }) => (
    <Fragment>
        <div className="card-body p-0">
            {title && <p className="fw-700 lh-26 font-xsss w-100 mb-0">{title}</p>}
        </div>

        <div className="card w-100 shadow-xss rounded-xxl overflow-hidden border-0 mb-4 mt-3">
            <div className="card-body position-relative h150 bg-image-cover bg-image-center"
            style={{backgroundImage: `url("/assets/images/bg/birthday.jpg")`}}></div>
            <div className="card-body d-block pt-4 text-center">
                <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto"><img src="/assets/images/logo_green.png" alt={title} className="p-1 bg-white rounded-xl w-100 aspect-ratio-1" style={{objectFit: "contain"}} /></figure>
                <h4 className="font-xs ls-1 fw-700 text-grey-900">
                    <span className="fw-700 text-success">Parabéns!</span>
                    <div className="fw-700 text-grey-500 lh-26 font-xsss w-100 mb-0 mt-4 mb-2 text-left" /*dangerouslySetInnerHTML={{ __html: HASHTAG_FORMATTER(des) }}*/>{ HASHTAG_FORMATTER(content) }</div>
                </h4>
            </div>
        </div>
        
        { attachments && attachments.length > 0 &&
        <div className="card-body p-0 mt-1 mb-3">
            <p className="fw-700 lh-26 font-xssss w-100 mb-0">Anexos</p>
            { attachments.map((attachment) => (
                <a key={attachment.id} href={getAttachmentUrl(attachment.file)} className="d-block font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-xss text-grey-500 feather-paperclip me-2"></i><span>{ attachment.name || attachment.file }</span></a>
            ))}                
        </div>
        }
    </Fragment>
);

const EventPostContent = ({ title, content, postImage, postVideo, attachments, employee, event }) => {

    if(!event) return null;

    const startDate = new Date(event.startDate);
    const endDate = event.endDate && new Date(event.endDate);

    const isSameDay = endDate && (startDate.getDate() === endDate.getDate() 
                            && startDate.getMonth() === endDate.getMonth()
                            && startDate.getFullYear() === endDate.getFullYear());

    return (
        <Fragment>
            <div className="card-body p-0">
                {employee && <p className="fw-700 text-grey-500 lh-26 font-xssss w-100 mb-0"><Link to={`/employees/${employee.id}`} className="fw-700 text-success">@{employee.name}</Link></p>}
                {false && <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2"><a href="/defaultvideo" className="fw-600 text-primary">See more</a></p>}
            </div>

            <div className="card w-100 shadow-xss rounded-xxl overflow-hidden border-0 mb-3 mt-3">
                <div className="card-body position-relative h150 bg-image-cover bg-image-center"
                style={{backgroundImage: `url("${(event.thumbnail && getImageUrl(event.thumbnail)) || '/assets/images/bg/event.jpg'}")`}}></div>
                <div className="card-body d-block pt-4">
                    <h4 className="font-xs ls-1 fw-700 text-grey-900 text-center">
                        <Link to={`/events/${event.id}`} className="fw-700 text-success">{event.title}</Link>
                        <span className="d-block font-xss fw-600 mt-1 lh-3 text-grey-500"><i className="feather-calendar me-1"></i> <Moment format='DD [de] MMMM [de] YYYY [às] HH:mm'>{ event.startDate }</Moment> {false && event.endDate && <span> - <Moment format={isSameDay ? 'HH:mm' : 'DD/MM/YYYY HH:mm'}>{ event.endDate }</Moment></span>}</span>
                        { event.location && <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500"><i className="feather-map-pin me-1"></i> { event.location }</span> }
                    </h4>                    
                </div>
            </div>

            <div className="card-body p-0 mb-4">
                <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-0 mb-2" /*dangerouslySetInnerHTML={{ __html: HASHTAG_FORMATTER(des) }}*/>{ HASHTAG_FORMATTER(content) }</p>
            </div>
            
            { attachments && attachments.length > 0 &&
            <div className="card-body p-0 mt-1 mb-3">
                <p className="fw-700 lh-26 font-xssss w-100 mb-0">Anexos</p>
                { attachments.map((attachment) => (
                    <a key={attachment.id} href={getAttachmentUrl(attachment.file)} className="d-block font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-xss text-grey-500 feather-paperclip me-2"></i><span>{ attachment.name || attachment.file }</span></a>
                ))}                
            </div>
            }
        </Fragment>
    );
}

const BenefitPostContent = ({ title, content, postImage, postVideo, attachments, benefit }) => {

    if(!benefit) return null;

    return (
        <Fragment>
            <div className="card-body p-0">
                {title && <p className="fw-700 lh-26 font-xsss w-100 mb-0">{title}</p>}
            </div>

            <div className="card w-100 shadow-xss rounded-xxl overflow-hidden border-0 mb-4 mt-3">
                <div className="card-body position-relative h150 bg-image-cover bg-image-center"
                style={{backgroundImage: `url("/assets/images/bg/benefit.jpg")`}}></div>
                <div className="card-body d-block pt-4 text-center">
                    <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto"><img src={getImageUrl(benefit.thumbnail, "/assets/images/no-image.jpg")} alt={benefit.name} className="p-1 bg-white rounded-xl w-100 aspect-ratio-1" /></figure>
                    <h4 className="font-xs ls-1 fw-700 text-grey-900">
                        <Link to={`/benefits/${benefit.id}`} className="fw-700 text-success">{benefit.name}</Link>
                        <span className="d-block fw-700 mt-1 lh-3 text-grey-500 font-xsss">{ benefit.category?.name }</span>
                    </h4>
                </div>
            </div>

            <div className="card-body p-0 mb-4">
                <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-0 mb-2" /*dangerouslySetInnerHTML={{ __html: HASHTAG_FORMATTER(des) }}*/>{ HASHTAG_FORMATTER(content) }</p>
            </div>
            
            { attachments && attachments.length > 0 &&
            <div className="card-body p-0 mt-1 mb-3">
                <p className="fw-700 lh-26 font-xssss w-100 mb-0">Anexos</p>
                { attachments.map((attachment) => (
                    <a key={attachment.id} href={getAttachmentUrl(attachment.file)} className="d-block font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-xss text-grey-500 feather-paperclip me-2"></i><span>{ attachment.name || attachment.file }</span></a>
                ))}                
            </div>
            }
        </Fragment>
    );
};

const NewsArticlePostContent = ({ title, content, postImage, postVideo, attachments, newsArticle, galleryId }) => {

    if(!newsArticle) return null;

    return (
        <Fragment>
            {postImage ?
            <div className="card-body d-block p-0 mb-3">
                <div className="row ps-2 pe-2">
                    <div className="col-sm-12 p-1"><img src={getImageUrl(newsArticle?.thumbnail, '/assets/images/no-image.jpg')} className="rounded-3 w-100" alt="" /></div>                                        
                </div>
            </div>
            : ''}
            {postVideo ?  
            <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
                <a href="#" className="video-btn">
                    <video autoPlay loop className="float-right w-100">
                        <source src={postVideo} type="video/mp4" />
                    </video>
                </a>
            </div>
            : ''}

            <div className="card-body p-0 me-lg-5">
                <span className="font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 bg-success d-inline-block text-white ">Notícia</span>
                <p className="fw-700 lh-26 font-xs w-100 mt-3 mb-0">{newsArticle?.title}</p>
                <div className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-0 mb-2" /*dangerouslySetInnerHTML={{ __html: HASHTAG_FORMATTER(des) }}*/>{ HASHTAG_FORMATTER(newsArticle?.headline) }</div>
                <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-4"><Link to={`/news/${newsArticle?.id}`} className="fw-600 text-success">Ler mais</Link></p>
            </div>
            
            { attachments && attachments.length > 0 &&
            <div className="card-body p-0 mt-1 mb-3">
                <p className="fw-700 lh-26 font-xssss w-100 mb-0">Anexos</p>
                { attachments.map((attachment) => (
                    <a key={attachment.id} href={getAttachmentUrl(attachment.file)} className="d-block font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-xss text-grey-500 feather-paperclip me-2"></i><span>{ attachment.name || attachment.file }</span></a>
                ))}                
            </div>
            }

            <Gallery id={galleryId} maxImages={6} />
        </Fragment>
    );
};

const PostReactionsModal = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
          <PostReactions {...props} />
      </Modal>
    );
}

function Postview(props) {

    const navigate = useNavigate();

    const { trackEvent } = useTracking();

    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const [isCommentsOpen, setIsCommentsOpen] = useState(false);

    const [isViewed, setIsViewed] = useState(false);

    const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);
    const toggleActive = (userReaction) => {
        if(!isActive && userReaction) {
            handleDeleteReaction(userReaction.id)
        } else {
            setIsActive(!isActive);
        }
    }

    const toggleCommentsOpen = () => setIsCommentsOpen(!isCommentsOpen);
    
    const {user ,time , title, des, avater , postimage , postvideo, id, type, pin, comments, reactions, handleLike, handleLove, handleDeleteReaction, handleCreateComment, handleCreateReply, currentUser, employee, allowComments, attachments, event, benefit, newsArticle, onPostUpdated, galleryId, surveyId, group } = props;
    
    const menuClass = `${isOpen ? " show" : ""}`;
    const emojiClass = `${isActive ? " active" : ""}`;

    const commentsCount = (comments || []).length;
    const reactionsLikeCount = (reactions || []).filter(r => r.type === 'LIKE').length;
    const reactionsLoveCount = (reactions || []).filter(r => r.type === 'LOVE').length;

    const hasUserReact = (reactions || []).filter(r => r.user.id === currentUser).length > 0;
    const userReaction = (hasUserReact && (reactions || []).filter(r => r.user.id === currentUser)[0]) || null;
    const reactionClass = `${hasUserReact ? " bg-grey p-2 rounded-xxl" : ""}`;

    const hasComments = comments && comments.length > 0;

    const [deletePostMutation, { deletePostMutationData }] = useMutation(DELETE_POST_MUTATION);
    const [upsertPostPinMutation, { upsertPostPinMutationData }] = useMutation(UPSERT_POST_PIN_MUTATION);

    const goToProfile = () => {
        if(user && user.employee) {
            navigate(`/employees/${user.employee.id}`)
        }
    }

    const deletePost = async () => {
        if (window.confirm('Tem a certeza que pretende eliminar a publicação?')) {
            const deleteResult = await deletePostMutation({ 
                variables: {
                    id
                },
            })

            if(deleteResult.data) {
                toast.success('Publicação eliminada com sucesso!')
                onPostUpdated && onPostUpdated()
            }
        }
    }

    const togglePostPin = async () => {
        if (window.confirm(pin ? 'Tem a certeza que pretende remover a publicação dos destaques?' : 'Tem a certeza que pretende destacar a publicação?')) {
            const upsertResult = await upsertPostPinMutation({ 
                variables: {
                    id,
                    isPinned: !pin
                },
            })

            if(upsertResult.data) {
                toast.success(`Publicação ${pin ? 'removida dos destaques' : 'destacada'}!`)
                onPostUpdated && onPostUpdated()
            }
        }
    }

    let postContent = null;
    if(type && type.toLowerCase() == 'badge_assignment') {
        postContent = <BadgeAssignmentPostContent title={title} content={des} postImage={postimage} postVideo={postvideo} attachments={attachments} employee={employee} galleryId={galleryId} />
    } else if(type && (type.toLowerCase() == 'employee_entry' || type.toLowerCase() == 'employee_exit')) {
        postContent = <EmployeeEntryPostContent title={title} content={des} postImage={postimage} postVideo={postvideo} attachments={attachments} employee={employee} galleryId={galleryId} />
    } else if(type && (type.toLowerCase() == 'birthday') && employee) {
        postContent = <BirthdayPostContent title={title} content={des} postImage={postimage} postVideo={postvideo} attachments={attachments} employee={employee} galleryId={galleryId} />
    } else if(type && (type.toLowerCase() == 'birthday') && !employee) {
        postContent = <BirthdaySummaryPostContent title={title} content={des} postImage={postimage} postVideo={postvideo} attachments={attachments} employee={employee} galleryId={galleryId} />
    } else if(type && (type.toLowerCase() == 'event')) {
        postContent = <EventPostContent title={title} content={des} postImage={postimage} postVideo={postvideo} attachments={attachments} employee={employee} event={event} galleryId={galleryId} />
    } else if(type && (type.toLowerCase() == 'benefit')) {
        postContent = <BenefitPostContent title={title} content={des} postImage={postimage} postVideo={postvideo} attachments={attachments} benefit={benefit} galleryId={galleryId} />
    } else if(type && (type.toLowerCase() == 'news_article')) {
        postContent = <NewsArticlePostContent title={title} content={des} postImage={postimage} postVideo={postvideo} attachments={attachments} newsArticle={newsArticle} galleryId={galleryId} />
    } else {
        postContent = <DefaultPostContent title={title} content={des} postImage={postimage} postVideo={postvideo} attachments={attachments} employee={employee} galleryId={galleryId} />
    }

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
            <div className="card-body p-0 d-flex">
                <figure className="avatar me-3 pointer" onClick={goToProfile}><img src={avater} alt={user.name} className="shadow-sm rounded-circle w45 aspect-ratio-1" /></figure>
                <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                    <span onClick={goToProfile}>{user.name}</span>
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                        <Link to={`/feed/${id}`} className="text-grey-500"><Moment fromNow>{time}</Moment></Link>
                        {' '}
                        {group && <Link to={`/groups/${group.id}`}><span className="font-xssss ms-1 ps-1 pe-1 lh-20 rounded-3 ls-2 bg-success d-inline-block text-white "><i class="text-white feather-globe me-1"></i>{group.name}</span></Link>}
                    </span>
                </h4>
                <div className="ms-auto">
                    <RBACWrapper requiredRoles={['admin']} oneOf>
                    { (!type || type == '') && <a role="button" className="fw-600 text-success lh-26 font-xssss ms-auto me-1" onClick={ () => { setIsEditModalOpen(true); } }>Editar</a> }
                    <a role="button" className="fw-600 text-danger text-dark lh-26 font-xssss ms-1" onClick={ () => { togglePostPin(); } }>{ pin ? <span><i className="ti-pin-alt text-warning font-xs"></i><i className="ti-close text-warning font-xsssss mt-2"></i></span> : <i className="ti-pin-alt text-warning btn-round-sm font-xs"></i> }</a>
                    <a role="button" className="fw-600 text-danger text-dark lh-26 font-xssss ms-1" onClick={ () => { deletePost(); } }><i className="feather-trash-2 text-danger btn-round-sm font-xs"></i></a>
                    </RBACWrapper>

                    { false && (currentUser && currentUser === user?.id) &&
                    <RBACWrapper requiredRoles={['post-contributor']} oneOf>
                    { (!type || type == '') && <a role="button" className="fw-600 text-success lh-26 font-xssss ms-auto me-1" onClick={ () => { setIsEditModalOpen(true); } }>Editar</a> }
                    <a role="button" className="fw-600 text-danger text-dark lh-26 font-xssss ms-auto" onClick={ () => { togglePostPin(); } }>{ pin ? <span><i className="ti-pin-alt text-warning text-grey-900  font-xs"></i><i className="ti-close text-dark text-grey-900 font-xsssss mt-2"></i></span> : <i className="ti-pin-alt text-dark text-grey-900 btn-round-sm font-xs"></i> }</a>
                    <a role="button" className="fw-600 text-danger text-dark lh-26 font-xssss ms-1" onClick={ () => { deletePost(); } }><i className="feather-trash-2 text-danger btn-round-sm font-xs"></i></a>
                    </RBACWrapper>
                    }

                    {pin && <i className="ti-pin-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>}
                </div>
            </div>

            <InView
                onChange={async (inView) => {
                    if (inView && !isViewed) {
                        setIsViewed(true)
                        //console.log('post InView: ' + id)
                        trackEvent({ event: "post-view", customData: id });
                    }
                }}
            />
            
            {postContent}

            { surveyId && <SurveyView id={surveyId} /> }

            <div className="card-body d-flex p-0">
                <div className={`emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2 ${reactionClass}`} onClick={ () => { toggleActive(userReaction) }}><i className="feather-thumbs-up text-white bg-primary-gradiant me-1 btn-round-xs font-xss"></i> {reactionsLikeCount} <i className="feather-heart text-white bg-red-gradiant ms-2 me-2 btn-round-xs font-xss"></i>{ reactionsLoveCount }</div>
                <div className={`emoji-wrap pointer ${emojiClass}`}>
                    <ul className="emojis list-inline mb-0">
                        { handleLike && <li className="emoji list-inline-item" onClick={ () => { handleLike(); toggleActive(); } }><i className="em em---1"></i> </li> }
                        { handleLove && <li className="emoji list-inline-item" onClick={ () => { handleLove(); toggleActive(); } }><i className="em em-heart"></i></li> }
                        { false && <li className="emoji list-inline-item"><i className="em em-angry"></i></li> }
                        { false && <li className="emoji list-inline-item"><i className="em em-anguished"></i> </li> }
                        { false && <li className="emoji list-inline-item"><i className="em em-astonished"></i> </li> }
                        { false && <li className="emoji list-inline-item"><i className="em em-blush"></i></li> }
                        { false && <li className="emoji list-inline-item"><i className="em em-clap"></i></li> }
                        { false && <li className="emoji list-inline-item"><i className="em em-cry"></i></li> }
                        { false && <li className="emoji list-inline-item"><i className="em em-full_moon_with_face"></i></li> }
                    </ul>
                </div>
                { (allowComments || hasComments) && <a role="button" className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss" onClick={ () => { toggleCommentsOpen(); } }><i className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i><span className="d-none-xss">{ commentsCount + (commentsCount === 1 ? ' comentário' : ' comentários') }</span></a> }
                
                { /*
                <RBACWrapper requiredRoles={['admin']} oneOf>
                <a role="button" className="d-flex align-items-center fw-600 text-danger text-dark lh-26 font-xssss ms-auto" onClick={ () => { togglePostPin(); } }>{ pin ? <span><i className="ti-pin-alt text-dark text-grey-900  font-xs"></i><i className="ti-close text-dark text-grey-900 font-xsssss mt-2"></i></span> : <i className="ti-pin-alt text-dark text-grey-900 btn-round-sm font-xs"></i> }</a>
                <a role="button" className="d-flex align-items-center fw-600 text-danger text-dark lh-26 font-xssss ms-1" onClick={ () => { deletePost(); } }><i className="feather-trash-2 text-danger btn-round-sm font-xs"></i></a>
                </RBACWrapper>

                { (currentUser && currentUser === user?.id) &&
                <RBACWrapper requiredRoles={['post-contributor']} oneOf>
                <a role="button" className="d-flex align-items-center fw-600 text-danger text-dark lh-26 font-xssss ms-auto" onClick={ () => { togglePostPin(); } }>{ pin ? <span><i className="ti-pin-alt text-dark text-grey-900  font-xs"></i><i className="ti-close text-dark text-grey-900 font-xsssss mt-2"></i></span> : <i className="ti-pin-alt text-dark text-grey-900 btn-round-sm font-xs"></i> }</a>
                <a role="button" className="d-flex align-items-center fw-600 text-danger text-dark lh-26 font-xssss ms-1" onClick={ () => { deletePost(); } }><i className="feather-trash-2 text-danger btn-round-sm font-xs"></i></a>
                </RBACWrapper>
                }
                */}
                <a role="button" className="d-flex align-items-center fw-600 text-danger text-dark lh-26 font-xssss ms-auto" onClick={ () => { setIsReactionsModalOpen(true); } }>{reactions?.length} {reactions?.length == 1 ? "reação" : "reações"}</a>
                
                {false &&
                <Fragment>
                <div className={`pointer ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss ${menuClass}`} id={`dropdownMenu${id}`} data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleOpen}><i className="feather-share-2 text-grey-900 text-dark btn-round-sm font-lg"></i><span className="d-none-xs">Share</span></div>
                <div className={`dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg right-0 ${menuClass}`} aria-labelledby={`dropdownMenu${id}`}>
                    <h4 className="fw-700 font-xss text-grey-900 d-flex align-items-center">Share <i className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 me-2"></i></h4>
                    <div className="card-body p-0 d-flex">
                        <ul className="d-flex align-items-center justify-content-between mt-2">
                            <li className="me-1"><span className="btn-round-lg pointer bg-facebook"><i className="font-xs ti-facebook text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-twiiter"><i className="font-xs ti-twitter-alt text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-linkedin"><i className="font-xs ti-linkedin text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-instagram"><i className="font-xs ti-instagram text-white"></i></span></li>
                            <li><span className="btn-round-lg pointer bg-pinterest"><i className="font-xs ti-pinterest text-white"></i></span></li>
                        </ul>
                    </div>
                    <div className="card-body p-0 d-flex">
                        <ul className="d-flex align-items-center justify-content-between mt-2">
                            <li className="me-1"><span className="btn-round-lg pointer bg-tumblr"><i className="font-xs ti-tumblr text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-youtube"><i className="font-xs ti-youtube text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-flicker"><i className="font-xs ti-flickr text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-black"><i className="font-xs ti-vimeo-alt text-white"></i></span></li>
                            <li><span className="btn-round-lg pointer bg-whatsup"><i className="font-xs feather-phone text-white"></i></span></li>
                        </ul>
                    </div>
                    <h4 className="fw-700 font-xssss mt-4 text-grey-500 d-flex align-items-center mb-3">Copy Link</h4>
                    <i className="feather-copy position-absolute right-35 mt-3 font-xs text-grey-500"></i>
                    <input type="text" placeholder="https://socia.be/1rGxjoJKVF0" className="bg-grey text-grey-500 font-xssss border-0 lh-32 p-2 font-xssss fw-600 rounded-3 w-100 theme-dark-bg" />
                </div>
                </Fragment>
                }
            </div>
            { isCommentsOpen && <Comments postId={id} comments={comments} currentUser={currentUser} commentsEnabled={allowComments} handleCreateComment={handleCreateComment} handleCreateReply={handleCreateReply} onCommentDeleted={onPostUpdated} type="comment" /> }

            <PostReactionsModal
                show={isReactionsModalOpen}
                onHide={() => setIsReactionsModalOpen(false)}
                id={id}
            />

            <EditPostModal
                show={isEditModalOpen}
                onHide={() => {
                    setIsEditModalOpen(false)
                    onPostUpdated && onPostUpdated()
                }}
                id={id}
            />

        </div>
    );
}

export default Postview;
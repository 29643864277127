import React,{Component, useContext, useState, useEffect} from 'react';
import { Link , NavLink } from 'react-router-dom';

import { RBACWrapper } from "react-simple-rbac";

import Darkbutton from '../components/Darkbutton';
import NotificationsDropdown from './NotificationsDropdown';

import { getInitials } from '../utils';
import { ENABLED_FEATURES, config } from '../config';
import NotificationModal from './NotificationModal';
import { useAuth } from '../providers/authProvider';

function Header() {

    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const [currentUser, setCurrentUser] = useState(null);
    
    const auth = useAuth()
    
    useEffect(() => {
        if(auth.isAuthenticated()) {
            auth.getUser().then(u => {
                setCurrentUser(u?.profile)
            })
        }
        //setCurrentUser(profile)
    }, [auth])

    const toggleOpen = () => setIsOpen(!isOpen);
    const toggleActive = () => setIsActive(!isActive);

    const navClass = `${isOpen ? " nav-active" : ""}`;
    const buttonClass = `${isOpen ? " active" : ""}`;
    const searchClass = `${isActive ? " show" : ""}`;

    const userInitials = getInitials(currentUser?.name);

    return (
        <div className="nav-header bg-white shadow-xs border-0">
            <div className="nav-top">
                <Link to="/feed">{ true && <i className="icon-empathize text-success display2-size me-2 ms-0"></i> } <span className="d-inline-block fredoka-font ls-3 fw-600 text-success font-xxl logo-text mb-0">empathize </span> </Link>
                { false && <Link to="/defaultmessage" className="mob-menu ms-auto me-2 chat-active-btn"><i className="feather-message-circle text-grey-900 font-sm btn-round-md bg-greylight"></i></Link> }
                { false && <Link to="/defaultvideo" className="mob-menu me-2"><i className="feather-video text-grey-900 font-sm btn-round-md bg-greylight"></i></Link> }
                <span onClick={toggleActive} className="ms-auto me-2 menu-search-icon mob-menu"><i className="feather-search text-grey-900 font-sm btn-round-md bg-greylight"></i></span>
                <button onClick={toggleOpen} className={`nav-menu me-0 ms-2 ${buttonClass}`}></button>
            </div>
            
            <form className="float-left header-search ms-3" action="/employees" method="get">
                <div className="form-group mb-0 icon-input">
                    <i className="feather-search font-sm text-grey-400"></i>
                    <input type="text" placeholder="Pesquisa..." className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg" name="q" autoComplete="off" />
                </div>
            </form>
            <NavLink activeclassname="active" to="/feed" className="p-2 text-center ms-3 menu-icon center-menu-icon"><i className="feather-home font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
            <NavLink activeclassname="active" to="/employees" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-users font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
            <NavLink activeclassname="active" to="/market" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-shopping-bag font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
            <NavLink activeclassname="active" to="/learning" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-book font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
            { config.FEATURES_PREVIEW_ENABLED && <NavLink activeclassname="active" to="/okr/objectives" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-target font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> }
            { config.FEATURES_PREVIEW_ENABLED && <NavLink activeclassname="active" to="/defaultstorie" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-zap font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> }
            { config.FEATURES_PREVIEW_ENABLED && <NavLink activeclassname="active" to="/defaultvideo" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-video font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> }
            { config.FEATURES_PREVIEW_ENABLED && <NavLink activeclassname="active" to="/defaultgroup" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-user font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> }
            { config.FEATURES_PREVIEW_ENABLED && <NavLink activeclassname="active" to="/shop2" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-shopping-bag font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> }

            <NotificationsDropdown/>
            
            { false && <Link to="/messages" className="p-2 text-center ms-3 menu-icon chat-active-btn"><i className="feather-message-square font-xl text-current"></i></Link> }
            { false && <Darkbutton /> }
            {false && <Link to="/settings" className="p-0 ms-3 menu-icon"><img src="assets/images/user.png" alt="user" className="w40 mt--1" /></Link>}
            <Link to="/settings" className="p-0 ms-3 menu-icon"><span className="btn-round-sm bg-primary-gradiant mt--1 ls-3 text-white font-xssss fw-700">{userInitials}</span></Link>

            <nav className={`navigation scroll-bar ${navClass}`}>
                <div className="container ps-0 pe-0">
                    <div className="nav-content">
                        <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
                            <div className="nav-caption fw-600 font-xssss text-grey-500"><span>Menu </span></div>
                            <ul className="mb-1 top-content">
                                <li className="logo d-none d-xl-block d-lg-block"></li>
                                <li><Link to="/feed" className="nav-content-bttn open-font"><i className="feather-home btn-round-md bg-primary-gradiant me-3"></i><span>Feed</span></Link></li>
                                <li><Link to="/companies" className="nav-content-bttn open-font"><i className="feather-codepen btn-round-md bg-primary-gradiant me-3"></i><span>Empresas</span></Link></li>
                                <li><Link to="/messages" className="nav-content-bttn open-font"><i className="feather-message-square btn-round-md bg-primary-gradiant me-3"></i><span>Mensagens</span></Link></li>
                                <li><Link to="/groups" className="nav-content-bttn open-font"><i className="feather-volume-2 btn-round-md bg-blue-gradiant me-3"></i><span>Engage</span></Link></li>
                                <li><Link to="/news" className="nav-content-bttn open-font"><i className="feather-tv btn-round-md bg-blue-gradiant me-3"></i><span>Notícias</span></Link></li>
                                <li><Link to="/events" className="nav-content-bttn open-font"><i className="feather-calendar btn-round-md bg-blue-gradiant me-3"></i><span>Eventos</span></Link></li>
                                <li><Link to="/moodtracker/calendar" className="nav-content-bttn open-font"><i className="feather-sun btn-round-md bg-gold-gradiant me-3"></i><span>Bem-estar</span></Link></li>
                                <li><Link to="/benefits" className="nav-content-bttn open-font"><i className="feather-gift btn-round-md bg-gold-gradiant me-3"></i><span>Benefícios</span></Link></li>
                                <li><Link to="/badges" className="nav-content-bttn open-font"><i className="feather-award btn-round-md bg-red-gradiant me-3"></i><span>Aplausos</span></Link></li>
                                <li><Link to="/market" className="nav-content-bttn open-font"><i className="feather-shopping-bag btn-round-md bg-red-gradiant me-3"></i><span>Market</span></Link></li>
                                { ENABLED_FEATURES.feedback && <li><Link to="/feedback" className="nav-content-bttn open-font"><i className="feather-message-circle btn-round-md bg-red-gradiant me-3"></i><span>Feedback</span></Link></li> }
                                <li><Link to="/learning" className="nav-content-bttn open-font"><i className="feather-book btn-round-md bg-mini-gradiant me-3"></i><span>Learning</span></Link></li>
                                { config.FEATURES_PREVIEW_ENABLED && <li><Link to="/okr/objectives" className="nav-content-bttn open-font"><i className="feather-target btn-round-md bg-mini-gradiant me-3"></i><span>Objetivos (OKR)</span></Link></li> }
                                { config.FEATURES_PREVIEW_ENABLED && <li><Link to="/defaultstorie" className="nav-content-bttn open-font"><i className="feather-globe btn-round-md bg-gold-gradiant me-3"></i><span>Explore Stories1</span></Link></li> }
                                { config.FEATURES_PREVIEW_ENABLED && <li><Link to="/defaultgroup" className="nav-content-bttn open-font"><i className="feather-zap btn-round-md bg-mini-gradiant me-3"></i><span>Popular Groups</span></Link></li> }
                                { config.FEATURES_PREVIEW_ENABLED && <li><Link to="/userpage" className="nav-content-bttn open-font"><i className="feather-user btn-round-md bg-blue-gradiant me-3"></i><span>Author Profile </span></Link></li> }
                            </ul>
                        </div>

                        
                        <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
                            <div className="nav-caption fw-600 font-xssss text-grey-500"><span>Mais</span>...</div>
                            <ul className="mb-3">
                                <li><Link to="/employees/orgChart" className="nav-content-bttn open-font"><i className="font-xl text-current feather-layers me-3"></i><span>Organograma</span></Link></li>
                                <li><Link to="/documents" className="nav-content-bttn open-font"><i className="font-xl text-current feather-folder me-3"></i><span>Documentos</span></Link></li>
                                <li><Link to="/suggestions/new" className="nav-content-bttn open-font"><i className="font-xl text-current feather-archive me-3"></i><span>Ideias e Sugestões</span></Link></li>
                                <li><Link to="/recruitment/jobOpportunities" className="nav-content-bttn open-font"><i className="font-xl text-current feather-briefcase me-3"></i><span>Oportunidades Internas</span></Link></li>
                                <li><Link to="/scholarships/funds" className="nav-content-bttn open-font"><i className="font-xl text-current feather-book-open me-3"></i><span>Bolsas de Estudo</span></Link></li>
                                <li><Link to="/referrals" className="nav-content-bttn open-font"><i className="font-xl text-current feather-users me-3"></i><span>Refer a Friend</span></Link></li>
                                { config.FEATURES_PREVIEW_ENABLED && <li><Link to="/whistleblowing/complaints" className="nav-content-bttn open-font"><i className="font-xl text-current feather-volume-1 me-3"></i><span>Canal de Denúncias</span></Link></li> }
                                {false && <li><Link to="/performance" className="nav-content-bttn open-font"><i className="font-xl text-current feather-trending-up me-3"></i><span className="font-xsss">Avaliação de Desempenho</span></Link></li>}
                                { config.FEATURES_PREVIEW_ENABLED && <li><Link to="/defaultemailbox" className="nav-content-bttn open-font"><i className="font-xl text-current feather-inbox me-3"></i><span>Email Box</span><span className="circle-count bg-warning mt-1">584</span></Link></li> }
                                { config.FEATURES_PREVIEW_ENABLED && <li><Link to="/defaulthotel" className="nav-content-bttn open-font"><i className="font-xl text-current feather-home me-3"></i><span>Near Hotel</span></Link></li> }
                                { config.FEATURES_PREVIEW_ENABLED && <li><Link to="/defaultevent" className="nav-content-bttn open-font"><i className="font-xl text-current feather-map-pin me-3"></i><span>Latest Event</span></Link></li> }
                                { config.FEATURES_PREVIEW_ENABLED && <li><Link to="/defaultlive" className="nav-content-bttn open-font"><i className="font-xl text-current feather-youtube me-3"></i><span>Live Stream</span></Link></li> }
                            </ul>
                        </div>
                        
                        <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1">
                            <div className="nav-caption fw-600 font-xssss text-grey-500"><span></span> Conta</div>
                            <ul className="mb-1">
                                <li className="logo d-none d-xl-block d-lg-block"></li>
                                <li><Link to="/settings" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-settings me-3 text-grey-500"></i><span>Definições</span></Link></li>
                                <RBACWrapper requiredRoles={['admin']} oneOf>
                                <li className="d-none d-xl-block d-lg-block"><Link to="/admin" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-sliders me-3 text-grey-500"></i><span>Administração</span></Link></li>
                                </RBACWrapper>
                                { config.FEATURES_PREVIEW_ENABLED && <li><Link to="/defaultanalytics" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-pie-chart me-3 text-grey-500"></i><span>Analytics</span></Link></li> }
                                { config.FEATURES_PREVIEW_ENABLED && <li><Link to="/defaultmessage" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-message-square me-3 text-grey-500"></i><span>Chat</span><span className="circle-count bg-warning mt-0">23</span></Link></li> }
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            
            <div className={`app-header-search ${searchClass}`}>
                <form className="search-form" action="/employees" method="get">
                    <div className="form-group searchbox mb-0 border-0 p-1">
                        <input type="text" className="form-control border-0" placeholder="Pesquisa..." name="q" autoComplete="off" />
                        <i className="input-icon">
                            <ion-icon name="search-outline" role="img" className="md hydrated" aria-label="search outline"></ion-icon>
                        </i>
                        <span className="ms-1 mt-1 d-inline-block close searchbox-close">
                            <i className="ti-close font-xs" onClick={toggleActive}></i>
                        </span>
                    </div>
                </form>
            </div>
            
        </div>
    );
}

export default Header;
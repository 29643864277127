import React, { Fragment } from "react";

import { useQuery, gql } from '@apollo/client';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';

import Load from '../components/Load';

import { useParams } from "react-router";
import Pagetitle from "../components/Pagetitle";
import FeedSearchResults from "../components/FeedSearchResults";
import FeedSearchHeader from "../components/FeedSearchHeader";
import track from "react-tracking";

const GET_HASHTAG_QUERY = gql`
    query GetHashtag($id: String!) {
        HashtagById(id: $id) {
            id
            posts {
                id
                title
                content
                type
                isPinned
                allowComments
                user {
                    id
                    name
                    picture
                    employee {
                        id
                        name
                        jobTitle
                        birthdate
                        picture
                        user {
                            id
                            picture
                        }
                    }
                }
                thumbnail
                group {
                    id
                    name
                }
                employee {
                    id
                    name
                    birthdate
                    jobTitle
                }
                event {
                    id
                    title
                    startDate
                    endDate
                    location
                    thumbnail
                    createdDate
                }
                benefit {
                    id
                    name
                    thumbnail
                    category {
                        id
                        name
                    }
                }
                newsArticle {
                    id
                    title
                    headline
                    content
                    thumbnail
                    category {
                        id
                        name
                    }
                }
                mediaGallery {
                    id
                }
                survey {
                    id
                }
                createdDate
                comments {
                    id
                    content
                    user {
                        id
                        name
                        picture
                        employee {
                            id
                            name
                            jobTitle
                            birthdate
                        }
                    }
                    createdDate
                    replies {
                        id
                        content
                        user {
                            id
                            name
                            picture
                            employee {
                                id
                                name
                                jobTitle
                                birthdate
                            }
                        }
                        createdDate
                    }
                }
                reactions {
                    id
                    type
                    user {
                        id
                    }
                }
                attachments {
                    id
                    name
                    file
                }
            }
        }
    }
`;

function FeedHashtag() {

    const { hashtag } = useParams();

    const { data: postsData, loading: postsLoading, fetchMore: postsFetchMore, error: postsError, refetch: postsRefetch } = useQuery(GET_HASHTAG_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
        variables: { id: hashtag }
    });

    const posts = postsData?.HashtagById?.posts || [];

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <Pagetitle title={`#${hashtag}`} />
                                <FeedSearchHeader currentPage="hashtags" searchQuery={hashtag} />

                                <FeedSearchResults posts={posts} postsLoading={postsLoading} postsRefetch={postsRefetch} />
                                { postsLoading && <Load /> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Appfooter /> 
        </Fragment>
    );
}

//export default FeedHashtag;

export default track(
    { page: "FeedHashtag" },
    //{ dispatchOnMount: contextData => ({ event: "pageLoaded" }) }
)(FeedHashtag);
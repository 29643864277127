import React from 'react';
import { Link, useNavigate } from "react-router-dom";

import { useQuery, gql } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import Moment from 'react-moment';
import moment from 'moment';

import Load from './Load';

const GET_EVENTS_QUERY = gql`
    query GetEvents($currentDate: OffsetDateTime) {
        EventList(filter: { startDate: { _gte: $currentDate }}, orderBy: { startDate: ASC }, limit: 3) {
            id
            title
            startDate
            endDate
            location
            thumbnail
            createdDate
        }
    }
`;

const GET_PAST_EVENTS_QUERY = gql`
    query GetEvents($currentDate: OffsetDateTime) {
        EventList(filter: { endDate: { _lt: $currentDate }}, orderBy: { startDate: DESC }, limit: 3) {
            id
            title
            startDate
            endDate
            location
            thumbnail
            createdDate
        }
    }
`;

function EventsWidget() {

    const { loading, error, data } = useQuery(GET_EVENTS_QUERY, { variables: { currentDate: `${moment().format('YYYY-MM-DD')}T00:00:00+00:00` }});
    const { loading: pastEventsLoading, error: pastEventsError, data: pastEventsData } = useQuery(GET_PAST_EVENTS_QUERY, { variables: { currentDate: `${moment().format('YYYY-MM-DD')}T00:00:00+00:00` }});
    if (loading || pastEventsLoading) return <LoadingOverlayWrapper/>;

    if(loading || pastEventsLoading) return <Load/>;

    const nextEvents = (data?.EventList) || [];
    const pastEvents = (pastEventsData?.EventList) || [];

    const events = (nextEvents.length == 0) ? pastEvents : nextEvents;

    if(events.length === 0) return null;

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
            <div className="card-body d-flex align-items-center p-4">
                <h4 className="fw-700 mb-0 font-xssss text-grey-900">{ nextEvents.length > 0 ? "Próximos Eventos" : "Eventos Anteriores" } <i className="em em-date ms-1"></i></h4>
                <a href="/events" className="fw-600 ms-auto font-xssss text-success">Ver todos</a>
            </div>
            {events.map((value , index) => (
            <div key={index} className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden">
                <div className={`me-2 p-3 rounded-xxl ${moment().isSame(value.startDate, 'day') ? 'bg-success' : 'bg-warning'}`}><h4 className="fw-700 font-lg ls-3 lh-1 text-white mb-0"><span className="ls-1 d-block font-xsss text-white fw-600"><Moment format="MMM">{value.startDate}</Moment></span><Moment format="DD">{value.startDate}</Moment></h4></div>
                <h4 className="fw-700 text-grey-900 font-xssss mt-2">
                    <Link to={`/events/${value.id}`} className="text-dark">{value.title} </Link>
                    <span className="d-block font-xssss fw-500 mt-1 lh-4 text-grey-500"><i className="feather-clock me-1"></i> <Moment format="HH:mm">{value.startDate}</Moment></span> 
                    {value.location && <span className="d-block font-xssss fw-500 mt-1 lh-4 text-grey-500"><i className="feather-map-pin me-1"></i> {value.location}</span> }
                </h4>
            </div>
            ))}  
            <div className="card-body align-items-center p-4 text-center pt-0">
                <a href="/events/calendar" className="fw-600 ms-auto font-xssss text-success">
                    <i className="feather-calendar me-1"></i>
                    Calendário
                </a>
            </div>
        </div>
    );
}

export default EventsWidget;
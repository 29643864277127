import React, { Fragment, useRef } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

import Moment from "react-moment";
import moment from 'moment';
import { toast } from "react-toastify";

import CustomTuiCalendar from "../../components/calendar/CustomTuiCalendar";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';
import Pagetitle from "../../components/Pagetitle";
import { getImageUrl, getProfilePicture } from "../../utils";
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";


const start = new Date();
const end = new Date(new Date().setMinutes(start.getMinutes() + 60));
const attendees = [
  {
    id: "1",
    name: "Chin"
  },
  { id: "2", name: "Khanh" },
  { id: "3", name: "Linh" },
  { id: "4", name: "Hai" }
];
const schedules = [
  {
    id: "1",
    title: "Mua nuoc dum",
    calendarId: "1",
    category: "time",
    attendees: ["Chin"],
    isVisible: true,
    start,
    end
  },
  {
    id: "2",
    title: "Di lau nha",
    calendarId: "2",
    category: "time",
    attendees: ["Khanh"],
    isVisible: true,
    start: new Date(new Date().setHours(start.getHours() + 1)),
    end: new Date(new Date().setHours(start.getHours() + 2))
  },
  {
    id: "3",
    title: "Di don phong",
    calendarId: "3",
    category: "time",
    attendees: ["Hai"],
    isVisible: true,
    start: new Date(new Date().setHours(start.getHours() + 2)),
    end: new Date(new Date().setHours(start.getHours() + 4))
  },
  {
    id: "4",
    title: "Phai lam sao day",
    calendarId: "4",
    category: "time",
    attendees: ["Linh"],
    isVisible: true,
    start: new Date(new Date().setHours(start.getHours() + 2)),
    end: new Date(new Date().setHours(start.getHours() + 6))
  }
];

const colors = [
  {
    id: "1",
    color: "#ffffff",
    bgColor: "#34C38F",
    dragBgColor: "#34C38F",
    borderColor: "#34C38F"
  },
  {
    id: "2",
    color: "#ffffff",
    bgColor: "#F4696A",
    dragBgColor: "#F4696A",
    borderColor: "#F4696A"
  },
  {
    id: "3",
    color: "#ffffff",
    bgColor: "#00a9ff",
    dragBgColor: "#00a9ff",
    borderColor: "#00a9ff"
  },
  {
    id: "4",
    color: "#ffffff",
    bgColor: "#F2B34C",
    dragBgColor: "#F2B34C",
    borderColor: "#F2B34C"
  },
  {
    id: "5",
    color: "#ffffff",
    bgColor: "#74788D",
    dragBgColor: "#74788D",
    borderColor: "#74788D"
  },
  {
    id: "6",
    color: "#ffffff",
    bgColor: "#343A40",
    dragBgColor: "#343A40",
    borderColor: "#343A40"
  },
  {
    id: "7",
    color: "#000000",
    bgColor: "#FFFFFF",
    dragBgColor: "#FFFFFF",
    borderColor: "#FFFFFF"
  }
];

const calendars = [
  {
    id: "1",
    name: "Eventos"
  },
  {
    id: "2",
    name: "Aqua 2 Cleaning"
  },
  {
    id: "3",
    name: "Aqua 4 Cleaning"
  },
  {
    id: "4",
    name: "Luxury 6 Cleaning"
  },
  {
    id: "5",
    name: "Luxury 6 Management"
  },
  {
    id: "6",
    name: "Aqua 3 Management"
  },
  {
    id: "7",
    name: "Aqua 2 Management"
  }
];

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
        }
    }
`;

const GET_EVENTS_QUERY = gql`
    query GetEvents {
        EventList(orderBy: { startDate: ASC }) {
            id
            title
            description
            startDate
            endDate
            location
            thumbnail
            createdDate
            invitations {
                id
                guest {
                    id
                    name
                    picture
                    employee {
                        id
                        picture
                    }
                }
                status
            }
        }
    }
`;

const UPSERT_EVENT_INVITATION_MUTATION = gql`
    mutation UpsertEventInvitation($eventId: UUID!, $userId: UUID!) {
        upsert_EventInvitation(eventInvitation: { event: { id: $eventId }, inviter : { id: $userId }, guest: { id: $userId }, status: YES }) {
            id
        }
    }
`;

const DELETE_EVENT_INVITATION_MUTATION = gql`
    mutation DeleteEventInvitation($id: String!) {
        delete_EventInvitation(id: $id)
    }
`;


function EventCalendar() {
    
    const [upsertEventInvitationMutation, { upsertEventInvitationMutationData }] = useMutation(UPSERT_EVENT_INVITATION_MUTATION);
    const [deleteEventInvitationMutation, { deleteEventInvitationMutationData }] = useMutation(DELETE_EVENT_INVITATION_MUTATION);

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { loading, error, data, refetch } = useQuery(GET_EVENTS_QUERY);
    
    const childRef = useRef();

    const createEventInvitation = async (id) => {
        const upsertResult = await upsertEventInvitationMutation({ 
            variables: {
                userId: viewerData.Viewer.id,
                eventId: id,
            },
        });

        if(upsertResult.data) {
            refetch({ currentDate: `${moment().format('YYYY-MM-DD')}T00:00:00+00:00` })
        }
    }

    const deleteEventInvitation = async (id) => {
        const deleteResult = await deleteEventInvitationMutation({ 
            variables: {
                id: id,
            },
        });

        if(deleteResult.data) {
            refetch({ currentDate: `${moment().format('YYYY-MM-DD')}T00:00:00+00:00` })
        }
    }

    const formatCalendars = calendars.map((element) => ({
        ...colors.find((element2) => element2.id === element.id),
        ...element
    }));

    const events = data?.EventList?.map(e => (
        {
            id: e.id,
            title: e.title,
            body: e.description,
            calendarId: "1",
            category: "time",
            //attendees: ["Chin"],
            isVisible: true,
            start: moment(e.startDate).toDate(),
            end: moment(e.endDate).toDate(),
            location: e.location,
        }
    )) || [];

    console.log(schedules)
    console.log(events)

    return (
        <Fragment> 
            <Header />
            <Leftnav />
            
            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12">
                                <Pagetitle title="Calendário" customButton={{link: '/admin/events/new', icon: 'feather-plus', requiredRoles: ['admin', 'post-contributor']}} color="success"/>
                            </div>

                            <div className="col-lg-12 mb-3">
                                <div className="float-right">
                                    <Link to="/events" className="p-2 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl"><i className={`feather-list`}></i> Lista</Link>
                                </div>
                            </div>
                            
                            <CustomLoadingOverlay active={loading} />

                            <div className="col-md-12">
                                { events?.length > 0 && <CustomTuiCalendar
                                    ref={childRef}
                                    {...{
                                        isReadOnly: true,
                                        showSlidebar: false,
                                        showMenu: true,
                                        useCreationPopup: false,
                                        showViewOptions: false,
                                        // onCreate: () => {
                                        //   console.log("create that!!!");
                                        //   childRef.current.getAlert();
                                        // },
                                        // createText: "Tao moi",
                                        defaultView: "month",
                                        calendars: formatCalendars,
                                        schedules: events,
                                        //onBeforeCreateSchedule,
                                        //onBeforeUpdateSchedule,
                                        //onBeforeDeleteSchedule
                                    }}
                                /> }
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 

        </Fragment>
    );

}

export default EventCalendar;
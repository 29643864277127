import React from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';

import { APPLE_APPSTORE_URL, ENABLED_FEATURES, GOOGLE_PLAY_URL, config } from '../config';

const GET_QUERY = gql`
    query Get($id: UUID!) {
        AppInstallationList(filter: { device: { user: { id: { _eq: $id }}}}) {
            id
        }
    }
`;

function MobileAppCallToActionWidget({ user }) {

    const { loading, error, data } = useQuery(GET_QUERY, {
        variables: { id: user?.id },
        skip: !user
    });

    if(!data?.AppInstallationList || data?.AppInstallationList?.length > 0) return null;
    
    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
            <div className="card-body p-0 d-flex">
                <figure className="avatar me-3 mb-0"><img src='/assets/images/mobile_app_icon.png' alt={config.APP_NAME} className="shadow-sm w45 aspect-ratio-1" /></figure>
                <h4 className="fw-700 text-grey-900 font-xsss mt-auto mb-auto"> <span>{config.APP_NAME}</span></h4>
                <div className="ms-auto">
                    <i className="ti-mobile text-grey-900 btn-round-md bg-greylight font-xss"></i>
                </div>
            </div>

            <p className="fw-700 lh-26 font-xsss w-100 mb-0 text-center">Instala a app em segundos.</p>

            <div className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2 text-center">
                <p className="mb-0">Acede à App Store ou Google Play e faz o download da app.</p>
            </div>

            <div className="row">
                <div className="col-lg-6 text-center">
                    <a href={APPLE_APPSTORE_URL} target="_blank" rel="noopener noreferrer" ><img src='/assets/images/app_store.png' alt="Obter na App Store" className="h40 mb-2" /></a>
                </div>
                <div className="col-lg-6 text-center">
                    <a href={GOOGLE_PLAY_URL} target="_blank" rel="noopener noreferrer" ><img src='/assets/images/google_play.png' alt="Obter no Google Play" className="h40" /></a>
                </div>
            </div>
        </div>
    );
}

export default MobileAppCallToActionWidget;
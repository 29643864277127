import React, { Fragment } from "react";

const pages = [
    { id: 'employees', searchUrl: '/employees/?q={0}', title: 'Colaboradores' },
    { id: 'companies', searchUrl: '/companies?q={0}', title: 'Empresas' },
    { id: 'groups', searchUrl: '/groups?q={0}', title: 'Engage' },
    { id: 'posts', searchUrl: '/feed/search/{0}', title: 'Publicações' },
    { id: 'hashtags', searchUrl: '/feed/hashtags/{0}', title: '#hashtag' },
]

function FeedSearchHeader({ currentPage, searchQuery }) {

    return (
        <div class="card-body d-block w-100 shadow-none mb-0 p-0 border-top-0 mb-3">
            <ul class="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4" id="pills-tab" role="tablist">
                { pages.map((p, index) => {
                    const additionalClass = index === (pages.length - 1) ? 'me-sm-5' : ''

                    return <li class={`${p.id === currentPage ? 'active' : ''} list-inline-item me-5`}><a class={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${p.id === currentPage ? 'active' : ''} ${additionalClass}`} href={p.searchUrl.format(searchQuery)} data-toggle={`tab-${p.id}`} key={p.id}>{p.title}</a></li>
                })}
            </ul>
        </div>
    );
}

export default FeedSearchHeader;
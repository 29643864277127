import React from 'react';
import { Link } from 'react-router-dom';

import { useQuery, gql } from '@apollo/client';

import Load from './Load';
import { getProfilePicture } from '../utils';
import { isNonEmptyArray } from '@apollo/client/utilities';

const GET_EMPLOYEE_QUERY = gql`
    query GetEmployee($id: String!) {
        EmployeeById(id: $id) {
            id
            name
            picture
            user {
                id
                picture
            }
            manager {
                id
                name
                picture
                user {
                    id
                    picture
                }
            }
        }
    }
`;

const GET_TEAM_EMPLOYEES_QUERY = gql`
    query Get($employeeId: UUID, $managerId: UUID, $fetchRequests: Boolean!) {
        DirectReports: EmployeeList(filter: {
            AND: [
                { manager: { id: { _eq: $employeeId } } }
                { isActive: { _eq: true } }
            ]
        }, orderBy: { name: ASC }, limit: 9) @include(if: $fetchRequests) {
            id
            name
            picture
            user {
                id
                picture
            }
        }
        
        Peers: EmployeeList(filter: {
            AND: [
                { manager: { id: { _eq: $managerId } } }
                { isActive: { _eq: true } }
            ]
        }, orderBy: { name: ASC }, limit: 9)  @include(if: $fetchRequests) {
            id
            name
            picture
            user {
                id
                picture
            }
        }
    }
`;

function MyTeamWidget({ employeeId }) {

    const { data: employeeData, loading: employeeLoading, error: employeeError } = useQuery(GET_EMPLOYEE_QUERY, {
        variables: { id: employeeId },
        skip: !employeeId
    });

    const { data, loading, error } = useQuery(GET_TEAM_EMPLOYEES_QUERY, {
        variables: {
            employeeId: employeeId || "00000000-0000-0000-0000-000000000000",
            managerId: employeeData?.EmployeeById?.manager?.id || "00000000-0000-0000-0000-000000000000",
            fetchRequests: employeeId !== null
        },
        skip: !employeeId
    });

    if(!employeeId) return null;

    if(loading) return <Load/>;

    const getFirstName = (name) => {
        return name?.split(' ').length > 0 ? name?.split(' ')[0] : name;
    }

    if(data?.DirectReports?.length === 0 && data?.Peers?.length === 0) return null;

    const teamMembers = data?.DirectReports?.length > 0 ? data?.DirectReports : data?.Peers;
    const manager = data?.DirectReports?.length > 0 ? employeeData?.EmployeeById : employeeData?.EmployeeById?.manager;

    return (
        <div className="card w-100 border-0 shadow-xss rounded-xxl border-0 p-4 mb-3 overflow-hidden">
            <div className="card-body p-0 d-flex align-items-center mb-3">
                <h4 className="fw-700 font-xss text-grey-900 pt-0 mb-0">A Minha Equipa</h4>
                <Link to={`/employees/orgChart?employeeId=${manager?.id}&showDirectReports=true`} className="ps-3 pe-2 lh-32 d-flex align-items-center font-xssss fw-600 alert-success rounded-xxl text-success ms-auto">Ver todos <i className="font-xsss feather-chevron-right ms-1"></i></Link>
            </div>
            <div className="card-body p-0 d-block mb-4">
                <div className="owl-carousel owl-theme category-card overflow-visible owl-dot-nav nav-none style2 chatlist owl-loaded owl-drag">
                    <div className="owl-stage-outer">
                        <div className="owl-stage" style={{transform: "translate3d(0px, 0px, 0px)", transition: "all 0s ease 0s", width: "620px"}}>
                            <div className="owl-item active" style={{width: "auto", marginRight: "7px"}}>
                                <div className="item item-grey">
                                    <div className="card bg-transparent bg-transparent-card border-0 shadow-none">
                                        <div className="card-image text-center"><Link to={`/employees/${manager?.id}`}><img src={getProfilePicture(manager)} alt={manager?.name} className="d-inline p-0 aspect-ratio-1" /> <span className="d-block fw-600 font-xssss text-grey-600 text-dark mt-0">{getFirstName(manager?.name)}</span></Link></div>
                                    </div>
                                </div>
                            </div>
                            
                            { teamMembers?.map(m => (
                                <div key={m.id} className="owl-item active" style={{width: "auto", marginRight: "7px"}}>
                                    <div className="item dashed">
                                        <div className="card bg-transparent bg-transparent-card border-0 shadow-none">
                                            <div className="card-image text-center"><Link to={`/employees/${m.id}`}><img src={getProfilePicture(m)} alt={m.name} className="d-inline p-0 aspect-ratio-1" /> <span className="d-block fw-600 font-xssss text-grey-600 text-dark">{getFirstName(m.name)}</span></Link></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="owl-nav"><button type="button" role="presentation" className="owl-prev disabled"><i className="ti-angle-left"></i></button><button type="button" role="presentation" className="owl-next disabled"><i className="ti-angle-right"></i></button></div>
                    <div className="owl-dots disabled"></div>
                </div>
            </div>
        </div>
    );
}

export default MyTeamWidget;
import React, { Component, useState } from 'react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import Moment from 'react-moment';

import { getImageUrl } from '../utils';


function NewsArticleView(props) {

    const navigate = useNavigate();


    const { news } = props;

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 p-0 mb-3">
            {news?.thumbnail ?
            <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
                <img src={getImageUrl(news.thumbnail)} className="rounded-3 w-100" alt={news.title} />
            </div>
            : ''}

            <div className="card-body ps-4 pe-4 pt-0 pb-0 mb-3">
                <p className="fw-700 lh-26 font-xs w-100 mb-1 mt-4">{news?.title}</p>
                <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2">
                    {news?.category && <span><i className="font-xsss text-grey-500 feather-list me-1"></i> <a href={`/news?category=${news?.category?.id}`} className="fw-600 text-success">{news?.category?.name}</a></span>}
                </p>
                <p className="review-link font-xssss fw-600 text-grey-500 lh-3 mt-3"><Moment format="DD/MM/YYYY">{ news?.createdDate }</Moment></p>
                <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-0 mb-2">{ news?.headline }</p>
                <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-0 mb-2" dangerouslySetInnerHTML={{ __html: news?.content }}></p>
            </div>
        </div>
    );
}

export default NewsArticleView;
import React, { Fragment, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import Moment from 'react-moment';

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';
import Load from '../../components/Load';

import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";
import { getProfilePicture } from '../../utils';
import { config, GRAPHQL_MAX_RESULTS_LIMIT } from "../../config";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            username
            email
            isEmailVerified
            name
            picture
            employee {
                id
                fullName
                jobTitle
                phone
                birthdate
                picture
            }
        }
    }
`;


function Chat() {
    
    const { loading: viewerLoading, error: viewerError, data: viewerData } = useQuery(GET_VIEWER_QUERY);

    const navigate = useNavigate();

    const viewer = viewerData?.Viewer;

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0" style={{maxWidth: '100%'}}>
                        <div className="row">
                            <div className="col-xl-12 mb-3">
                                <div className="chat-wrapper p-3 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                    <Pagetitle title="empathize Chat" color="success" />
                                    <iframe
                                        src={`https://chat.empathize.pt/group/${window.location.host.split('.')[0]}?layout=embedded`}
                                        frameborder="0" height="100%" width="100%"
                                        style={{border: "none", overflow: "hidden", height: "100%", width: "100%"}}
                                    ></iframe>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 
        </Fragment>
    );
}

export default Chat;
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { getImageUrl, getProfilePicture } from '../utils';
import { ENABLED_FEATURES, UPLOAD_IMAGE_ACCEPT_FILETYPES } from '../config';

function ProfilecardThree(props) {

    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen(!isOpen);

    const menuClass = `${isOpen ? " show" : ""}`;

    const inputFile = useRef(null) 

    if(!props.profile) return "";

    const onFileChange = event => { 
        const file = event.target.files[0];
        console.log(file)
        props.handleUpdateCoverImage && props.handleUpdateCoverImage(file);
    };

    const deleteCoverImage = () => {
        props.handleUpdateCoverImage && props.handleUpdateCoverImage(null);
    }

    return (
        <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
            <div className={`card-body h250 p-0 rounded-xxl overflow-hidden m-3 bg-greylight ${props.isCurrentUser ? "pointer" : ""}`}><img src={props.profile.coverImage ? getImageUrl(props.profile.coverImage) : "/assets/images/abstract-bg-green.png"} alt={props.profile.name} style={{maxHeight: '250px', width: '100%', objectFit: 'cover'}}  onClick={() => props.isCurrentUser && inputFile.current.click()} /></div>
            <input type="file" name="file" id="file" ref={inputFile} className="input-file" onChange={onFileChange} accept={UPLOAD_IMAGE_ACCEPT_FILETYPES} style={{display: 'none'}} />
            <div className="card-body p-0 position-relative">
                <figure className="avatar position-absolute w100 z-index-1" style={{top:'-40px',left:'30px'}}><img src={getProfilePicture(props.profile)} alt={props.profile.name} className="float-right p-1 bg-white rounded-circle w-100 aspect-ratio-1" /></figure>
                <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">{props.profile.name} <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">{props.profile.jobTitle}</span></h4>
                <div className="d-flex align-items-center justify-content-center position-absolute-md right-15 top-0 me-2 mb-3">
                    { false && <Link to={`/badges/give/${props.profile.id}`} className="d-none d-lg-block bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">Dar Aplausos</Link>}
                    { false && <Link to={`/feedback/give/${props.profile.id}`} className="d-none d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"><i className="feather-message-square font-md"></i></Link>}
                    { false && <a role="button" className="d-none d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"  id={`dropdownMenu4`} data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleOpen}><i className="feather-more-horizontal font-md text-dark"></i></a>}
                    { props.isCurrentUser && <Link to={`/account`} className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">Editar</Link>}
                    { !props.isCurrentUser && <Link to={`/badges/give/${props.profile.id}`} className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">Dar Aplausos</Link>}
                    { !props.isCurrentUser && props.profile?.user?.id &&<Link to={`/messages/${props.profile.user.id}`} className="bg-success btn-round-lg ms-2 rounded-3 text-white"><i className="feather-message-square font-md"></i></Link>}
                    { ENABLED_FEATURES.feedback && !props.isCurrentUser &&<Link to={`/feedback/give/${props.profile.id}`} className="bg-success btn-round-lg ms-2 rounded-3 text-white"><i className="feather-message-square font-md"></i></Link>}
                    <a role="button" className="bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"  id={`dropdownMenu4`} data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleOpen}><i className="feather-more-horizontal font-md text-dark"></i></a>
                    <div className={`dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg ${menuClass}`} aria-labelledby={`dropdownMenu4`}>
                        <div className="card-body p-0 d-flex">
                            <Link to={`/employees/orgChart?employeeId=${props.profile.id}`}><i className="feather-layers text-grey-500 me-3 font-lg"></i></Link>
                            <Link to={`/employees/orgChart?employeeId=${props.profile.id}`}><h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">Organograma <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Ver no gráfico da organização</span></h4></Link>
                        </div>
                        { ENABLED_FEATURES.feedback && !props.isCurrentUser &&
                        <div className="card-body p-0 d-flex mt-2">
                            <Link to={`/feedback/requests/new/${props.profile.id}`}><i className="feather-users text-grey-500 me-3 font-lg"></i></Link>
                            <Link to={`/feedback/requests/new/${props.profile.id}`}><h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">Pedir feedback <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Feedback contínuo</span></h4></Link>
                        </div>
                        }
                        { props.isCurrentUser &&
                        <div className="card-body p-0 d-flex mt-2">
                            <a role="button" onClick={() => inputFile.current.click()}><i className="feather-image text-grey-500 me-3 font-lg"></i></a>
                            <a role="button" onClick={() => inputFile.current.click()}><h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">Alterar imagem de capa</h4></a>
                        </div>
                        }
                        { props.isCurrentUser && props.profile.coverImage &&
                        <div className="card-body p-0 d-flex mt-2">
                            <a role="button" onClick={deleteCoverImage}><i className="feather-x text-grey-500 me-3 font-lg"></i></a>
                            <a role="button" onClick={deleteCoverImage}><h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">Remover imagem de capa</h4></a>
                        </div>
                        }
                        { false &&
                        <div className="card-body p-0 d-flex mt-2">
                            <i className="feather-alert-octagon text-grey-500 me-3 font-lg"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">Hide all from Group <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                        </div>
                        }
                        { false &&
                        <div className="card-body p-0 d-flex mt-2">
                            <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                            <h4 className="fw-600 mb-0 text-grey-900 font-xssss mt-0 me-0">Unfollow Group <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                        </div>
                        }
                    </div>
                </div>
            </div>

            { false && 
            <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                <ul className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4" id="pills-tab" role="tablist">
                    <li className="active list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block active" href="#navtabs1" data-toggle="tab">About</a></li>
                    <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs2" data-toggle="tab">Membership</a></li>
                    <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs3" data-toggle="tab">Discussion</a></li>
                    <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs4" data-toggle="tab">Video</a></li>
                    <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs3" data-toggle="tab">Group</a></li>
                    <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs1" data-toggle="tab">Events</a></li>
                    <li className="list-inline-item me-5"><a className="fw-700 me-sm-5 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs7" data-toggle="tab">Media</a></li>
                </ul>
            </div>
            }
        </div>
            
    );
}

export default ProfilecardThree;
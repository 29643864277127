import React, { Fragment, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";

import { useQuery, gql, useMutation } from '@apollo/client';

import Moment from "react-moment";

import track, { useTracking } from "react-tracking";

import { RBACWrapper } from "react-simple-rbac";

import { getImageUrl, getProfilePicture } from "../../utils";
import moment from "moment";
import Gallery from "../../components/Gallery";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
        }
    }
`;


const GET_EVENT_QUERY = gql`
    query GetEvent($id: String!) {
        EventById(id: $id) {
            id
            title
            description
            startDate
            endDate
            location
            thumbnail
            category {
                id
                name
            }
            createdDate
            invitations {
                id
                guest {
                    id
                    name
                    picture
                    employee {
                        id
                        picture
                    }
                }
                status
            }
            mediaGallery {
                id
            }
        }
    }
`;

const UPSERT_EVENT_INVITATION_MUTATION = gql`
    mutation UpsertEventInvitation($eventId: UUID!, $userId: UUID!) {
        upsert_EventInvitation(eventInvitation: { event: { id: $eventId }, inviter : { id: $userId }, guest: { id: $userId }, status: YES }) {
            id
        }
    }
`;

const DELETE_EVENT_INVITATION_MUTATION = gql`
    mutation DeleteEventInvitation($id: String!) {
        delete_EventInvitation(id: $id)
    }
`;

function EventSingle() {
    
    let { id } = useParams();

    const { trackEvent } = useTracking();
    
    const [upsertEventInvitationMutation, { upsertEventInvitationMutationData }] = useMutation(UPSERT_EVENT_INVITATION_MUTATION);
    const [deleteEventInvitationMutation, { deleteEventInvitationMutationData }] = useMutation(DELETE_EVENT_INVITATION_MUTATION);

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { loading, error, data, refetch } = useQuery(GET_EVENT_QUERY, { variables: { id: id }});

    useEffect(() => {
        trackEvent({ event: "view", customData: id });
    }, [])
    
    const createEventInvitation = async (id) => {
        const upsertResult = await upsertEventInvitationMutation({ 
            variables: {
                userId: viewerData.Viewer.id,
                eventId: id,
            },
        });

        if(upsertResult.data) {
            refetch()
        }
    }

    const deleteEventInvitation = async (id) => {
        const deleteResult = await deleteEventInvitationMutation({ 
            variables: {
                id: id,
            },
        });

        if(deleteResult.data) {
            refetch()
        }
    }
    
    const event = (data && data.EventById) || {};
    
    return (
        <Fragment> 
            <Header />
            <Leftnav />
            
            <CustomLoadingOverlay active={loading} />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12 col-lg-12">
                                <img src={getImageUrl(event?.thumbnail, '/assets/images/abstract-bg-green.png')} className="w-100" style={{maxHeight: 300, objectFit: 'cover'}} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-8 col-xxl-9 col-lg-8">

                                <div className="card d-block mt-3 border-0 shadow-xss bg-white p-lg-5 p-4">
                                    { event?.category?.name &&<span className="font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 bg-primary-gradiant d-inline-block text-white mb-3">{ event?.category?.name }</span> }
                                    <h2 className="fw-700 font-lg mb-2">{event?.title}</h2>
                                    <RBACWrapper requiredRoles={['admin', 'post-contributor']} oneOf>
                                    <Link to={`/admin/events/edit/${event?.id}`}>
                                        <i className={`feather-edit font-xss`}></i> Editar
                                    </Link>
                                </RBACWrapper>
                                    <p className="font-xsss fw-500 text-grey-500 lh-30 pe-5 mt-3 me-5 w-100" dangerouslySetInnerHTML={{ __html: event?.description }}></p>
                                    
                                    <div className="clearfix"></div>
                                    
                                    <h5 className="mt-4 d-inline-block font-xssss fw-600 text-grey-500 me-2"><i className="btn-round-sm bg-greylight ti-time text-grey-500 me-1"></i> <Moment format="DD/MM/YYYY HH:mm">{event?.startDate}</Moment>{event?.endDate && <span> - <Moment format="DD/MM/YYYY HH:mm">{event?.endDate}</Moment></span>}</h5>
                                    
                                    {event?.location && <h5 className="mb-4 d-inline-block font-xssss fw-600 text-grey-500 me-2"><i className="btn-round-sm bg-greylight ti-pin text-grey-500 me-1"></i> {event?.location}</h5>}
                                    <div className="clearfix"></div>

                                    {moment().diff(event?.startDate, 'days') < 0 && <>
                                    { event?.invitations?.filter(i => i.guest.id === viewerData.Viewer.id).length == 0 && <a role="button" className='bg-greylight border-0 text-grey-500 fw-600 text-uppercase font-xssss rounded-3 d-inline-block mt-2 p-2 lh-34 text-center ls-3 ps-3 pe-3' onClick={() => createEventInvitation(event?.id)}><i className="feather-star me-2"></i> TENHO INTERESSE</a> }
                                    { event?.invitations?.filter(i => i.guest.id === viewerData.Viewer.id).length > 0 && <a role="button" className='bg-primary-gradiant border-0 text-white fw-600 text-uppercase font-xssss rounded-3 d-inline-block mt-2 p-2 lh-34 text-center ls-3 ps-3 pe-3' onClick={() => deleteEventInvitation(event?.invitations.filter(i => i.guest.id === viewerData.Viewer.id)[0].id)}><i className="feather-thumbs-up me-2"></i>ESTOU INTERESSADO/A</a> }
                                    </>}
                                </div>

                                <Gallery title="Galeria" id={event?.mediaGallery?.id} showContainer={true} />

                            </div>
                            <div className="col-xl-4 col-xxl-3 col-lg-4 ps-0">

                                <div className="card w-100 border-0 mt-3 mb-4 p-lg-4 p-3 shadow-xss position-relative rounded-3 bg-white">
                                    <div className="section full mb-4 p-4 bg-light theme-dark-bg theme-light-bg rounded-3">
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <h2 className="display2-size lh-1 m-0 text-grey-900 fw-700">{event?.invitations?.length}</h2>
                                            </div>
                                            <div className="col-12 text-center">
                                                <h4 className="font-xsss text-grey-600 fw-600 mt-2">com interesse</h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="section full position-relative feed-body">
                                            <h4 className="font-xsssss text-grey-500 text-uppercase fw-700 ls-3">INTERESSADOS</h4>
                                            <ul className="list-group list-group-flush">
                                                {event?.invitations?.map((value , index) => (
                                                    <li  key={index} className="bg-transparent list-group-item no-icon pe-0 ps-0 pt-2 pb-2 border-0 d-flex align-items-center">
                                                        <figure className="avatar float-left mb-0 me-2">
                                                            <img src={getProfilePicture(value.guest)} alt={value.guest.name} className="w35" />
                                                        </figure>
                                                        <h3 className="fw-700 mb-0 mt-0">
                                                            <span className="font-xssss text-grey-600 d-block text-dark model-popup-chat pointer">{value.guest.name}</span>
                                                        </h3>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Appfooter /> 

        </Fragment>
    );
}

//export default EventSingle;

export default track(
    { page: "Event" },
    //{ dispatchOnMount: contextData => ({ event: "view" }) }
)(EventSingle);
import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import Moment from "react-moment";
import { toast } from "react-toastify";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';
import { getProfilePicture } from "../../../utils";
import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../components/Pagetitle";
import { GRAPHQL_MAX_RESULTS_LIMIT } from "../../../config";

const STATUS_FILTERS = [
    { id: 'all', name: 'Todos', value: { } },
    { id: 'active', name: 'Ativos', value: { _eq: true } },
    { id: 'not_active', name: 'Inativos', value: { _eq: false } },
];


const GET_EMPLOYEES_QUERY = gql`
    query GetEmployees($isActiveFilter: [inp_BooleanFilterCondition]) {
        EmployeeList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: { isActive: $isActiveFilter }) {
            id
            externalId
            name
            birthdate
            email
            company {
                id
                name
            }
            department {
                id
                name
            }
            jobTitle
            picture
            isExternal
            isActive
            hireDate
            terminationDate
            user {
                id
                picture
                isActive
            }
            createdDate
        }
    }
`;

const UPSERT_EMPLOYEE_MUTATION = gql`
    mutation UpsertEmployee($id: UUID, $isActive: Boolean!) {
        upsert_Employee(employee:{ id: $id, isActive: $isActive }) {
            id
        }
    }
`;

const DELETE_EMPLOYEE_MUTATION = gql`
    mutation DeleteEmployee($id: String!) {
        delete_Employee(id: $id)
    }
`;

function AdminEmployeeList() {

    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedIsActiveFilter, setSelectedIsActiveFilter] = useState({})

    const { loading, error, data, refetch } = useQuery(GET_EMPLOYEES_QUERY, { variables: { isActiveFilter: selectedIsActiveFilter }});

    const [upsertEmployeeMutation, { upsertEmployeeMutationData }] = useMutation(UPSERT_EMPLOYEE_MUTATION);
    const [deleteEmployeeMutation, { deleteEmployeeMutationData }] = useMutation(DELETE_EMPLOYEE_MUTATION);

    useEffect(() => {
        setIsActiveFilter("active")
    }, [])

    const updateEmployeeStatus = async (id, isActive) => {
        const upsertResult = await upsertEmployeeMutation({ 
            variables: {
                id,
                isActive
            },
        })
        await refetch();

        if(upsertResult.data) {
            toast.success(`Colaborador ${isActive ? 'ativado' : 'desativado'}`)
        }
    }

    const deleteEmployee = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar o colaborador?')) {
            const deleteResult = await deleteEmployeeMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('Colaborador eliminado com sucesso!')
            }
        }
    }

    const setIsActiveFilter = (id) => {
        setSelectedIsActiveFilter(STATUS_FILTERS.filter(e => e.id === id)[0].value)
    }

    const employees = (data && data.EmployeeList.slice().sort((a, b) => a.name.localeCompare(b.name))) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 cart-wrapper mb-4">
                                    <Pagetitle title="Colaboradores"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ employees.length } resultados</h4>
                                            <div className="float-right">
                                                <Link to="/admin/insights/employees" className="p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl"><i className={`feather-trending-up`}></i> Insights</Link>
                                                <Link to="/admin/users/signupRequests" className="p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Ver Pedidos de Registo</Link>
                                                <Link to="/admin/employees/new" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Novo Colaborador</Link>
                                                <Link to="/admin/employees/import" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Importar</Link>

                                                <select className="searchCat sort" onChange={e => setIsActiveFilter(e.target.value)} defaultValue={""}>
                                                    <option value="" disabled>Estado</option>
                                                    { STATUS_FILTERS.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                            <th className="border-0 p-4 text-left">Nome</th>
                                                            <th className="border-0 p-4 text-left">ID Externo</th>
                                                            <th className="border-0 p-4">Email</th>
                                                            <th className="border-0 p-4">Empresa</th>
                                                            <th className="border-0 p-4">Departmento</th>
                                                            <th className="border-0 p-4">Função</th>
                                                            <th className="border-0 p-4">Data de Admissão</th>
                                                            { false && <th className="border-0 p-4">Data de Rescisão</th> }
                                                            <th className="border-0 p-4">Ativo?</th>
                                                            <th className="border-0 p-4">Utilizador?</th>
                                                            { false && <th className="border-0 p-4">Data de Criação</th> }
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {employees.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-center ps-0">
                                                                <img src={getProfilePicture(value)} alt={value.name} className="w35 rounded-circle aspect-ratio-1" />
                                                            </td>
                                                            <td className="text-left wide-column">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss">
                                                                        <Link to={`/admin/employees/${value.id}`}>{value.name}</Link>{' '}
                                                                        { value.isExternal && <span className={`p-1 ps-2 pe-2 text-warning alert-warning font-xssss rounded-3`}><strong>Externo</strong></span> }
                                                                    </span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-500 font-xsss">{value.externalId}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-500 font-xsss">{value.email}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.company?.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.department?.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.jobTitle}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.hireDate ? <Moment format="DD/MM/YYYY">{value.hireDate}</Moment> : '-'}</span>
                                                            </td>
                                                            { false && <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.terminationDate ? <Moment format="DD/MM/YYYY">{value.terminationDate}</Moment> : '-'}</span>
                                                            </td> }
                                                            <td className="text-center"><i className={`${value.isActive ? ' ti-check-box text-success ' : ' ti-na text-danger '} font-xs`}></i></td>
                                                            <td className="text-center"><i className={`${value.user?.isActive ? ' feather-user-check text-success ' : ' feather-user-x text-danger '} font-xs`}></i></td>
                                                            {false && <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.createdDate ? <Moment format="DD/MM/YYYY HH:mm">{value.createdDate}</Moment> : '-'}</span>
                                                            </td> }
                                                            <td className="text-right">
                                                                <a role="button" onClick={() => updateEmployeeStatus(value.id, !value.isActive)}><i className={`${!value.isActive ? ' ti-check text-success ' : ' ti-na text-danger '} font-xs me-2`}></i></a>
                                                                <Link to={`/admin/employees/edit/${value.id}`}><i className="ti-pencil font-xs text-grey-500"></i></Link>
                                                                <a role="button" onClick={() => deleteEmployee(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminEmployeeList;
import React, { useContext, useEffect, useState } from 'react';
import { ApolloClient, createHttpLink, from, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { offsetLimitPagination } from "@apollo/client/utilities";

import { createUploadLink } from 'apollo-upload-client'

import { BrowserRouter, Routes, Route  } from 'react-router-dom';

import { RBACProvider } from 'react-simple-rbac';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import track from 'react-tracking';

import GlobalState from './context/GlobalState';

import { config } from './config';

import "react-multi-date-picker/styles/colors/green.css"

import './main.scss';

import Demo from './demo/Demo';

import Home from './pages/Home';
import Feed from './pages/Feed';
import FeedHashtag from './pages/FeedHashtag';

import Badge from './pages/Badge';
import Group from './pages/Group';
import Storie from './pages/Storie';
import Member from './pages/Member';
import Email from './pages/Email';
import Emailopen from './pages/Emailopen';
import Settings from './pages/Settings';
import Account from './pages/Account';
import EmployeeQRCode from './pages/EmployeeCardQRCode';
import Contactinfo from './pages/Contactinfo';
import Socialaccount from './pages/Socialaccount';
import Password from './pages/Password';
import Payment from './pages/Payment';
import Notification from './pages/Notification';
import Helpbox from './pages/Helpbox';
import Login from './pages/Login';
import Register from './pages/Register';
import Forgot from './pages/Forgot';
import Notfound from './pages/Notfound';

import ShopOne from './pages/ShopOne';
import ShopTwo from './pages/ShopTwo';
import ShopThree from './pages/ShopThree';
import Singleproduct from './pages/Singleproduct';
import { default as CartDemo } from './pages/Cart';
import { default as CheckoutDemo } from './pages/Checkout';
//import Chat from './pages/Chat';
import Live from './pages/Live';
import Job from './pages/Job';
import Event from './pages/Event';
import Hotel from './pages/Hotel';
import Videos from './pages/Videos';
import Comingsoon from './pages/Comingsoon';


import Grouppage from './pages/Grouppage';
import Userpage from './pages/Userpage';
import Authorpage from './pages/Authorpage';
import Hotelsingle from './pages/Hotelsingle';
import Analytics from './pages/Analytics';

import BenefitList from './pages/benefits/BenefitList';
import BenefitSingle from './pages/benefits/BenefitSingle';

import EmployeeList from './pages/employees/EmployeeList';
import EmployeeSingle from './pages/employees/EmployeeSingle';
import EmployeeOrgChart from './pages/employees/EmployeeOrgChart';

import BirthdayList from './pages/birthdays/BirthdayList';

import GiveBadge from './pages/badges/GiveBadge';
import EmployeeBadgeList from './pages/badges/EmployeeBadgeList';
import BadgeRanking from './pages/badges/BadgeRanking';
import BadgeInteractionsChart from './pages/badges/BadgeInteractionsChart';

import GiveFeedback from './pages/feedback/GiveFeedback';
import EmployeeFeedbackList from './pages/feedback/EmployeeFeedbackList';
import CreateFeedbackRequest from './pages/feedback/CreateFeedbackRequest';
import FeedbackRequestList from './pages/feedback/FeedbackRequestList';

import ReferFriend from './pages/referrals/ReferFriend';
import EmployeeReferralsList from './pages/referrals/EmployeeReferralsList';

import MoodTrackerCalendar from './pages/moodtracker/MoodTrackerCalendar';

import LearningItemSingle from './pages/learning/LearningItemSingle';
import LearningList from './pages/learning/LearningList';
import CreateSuggestion from './pages/suggestions/CreateSuggestion';
import NewsArticleSingle from './pages/news/NewsArticleSingle';
import NewsArticleList from './pages/news/NewsArticleList';
import EventList from './pages/events/EventList';
import EventSingle from './pages/events/EventSingle';
import ObjectiveList from './pages/okr/ObjectiveList';
import CreateObjective from './pages/okr/CreateObjective';
import CreateKeyResult from './pages/okr/CreateKeyResult';
import CreateKeyResultCheckin from './pages/okr/CreateKeyResultCheckin';
import CreateComplaint from './pages/whistleblowing/CreateComplaint';
import ComplaintsHome from './pages/whistleblowing/ComplaintsHome';
import FollowupComplaint from './pages/whistleblowing/FollowupComplaint';
import MarketHome from './pages/market/MarketHome';
import ProductSingle from './pages/market/ProductSingle';
import Checkout from './pages/market/Checkout';
import Cart from './pages/market/Cart';
import OrdersList from './pages/market/OrdersList';
import Wallet from './pages/Wallet';
import FAQs from './pages/support/FAQs';
import PostSingle from './pages/PostSingle';
import AdminEmployeeList from './pages/admin/employees/AdminEmployeeList';
import AdminImportEmployees from './pages/admin/employees/AdminImportEmployees';
import AdminNewEmployee from './pages/admin/employees/AdminNewEmployee';
import AdminNewTrainingAction from './pages/admin/training/trainingActions/AdminNewTrainingAction';
import AdminTrainingActionList from './pages/admin/training/trainingActions/AdminTrainingActionList';
import AdminTrainingAction from './pages/admin/training/trainingActions/AdminTrainingAction';
import AdminNewTrainingEntity from './pages/admin/training/trainingEntities/AdminNewTrainingEntity';
import AdminTrainingEntityList from './pages/admin/training/trainingEntities/AdminTrainingEntityList';
import AdminHome from './pages/admin/AdminHome';
import AdminQuickLinkList from './pages/admin/quickLinks/AdminQuickLinkList';
import AdminNewQuickLink from './pages/admin/quickLinks/AdminNewQuickLink';
import AdminTrainingEntity from './pages/admin/training/trainingEntities/AdminTrainingEntity';
import AdminNewTrainer from './pages/admin/training/trainers/AdminNewTrainer';
import AdminProductList from './pages/admin/market/AdminProductList';
import AdminNewProduct from './pages/admin/market/AdminNewProduct';
import AdminOrderList from './pages/admin/market/AdminOrderList';
import AdminEmployee from './pages/admin/employees/AdminEmployee';
import AdminNewCompany from './pages/admin/companies/AdminNewCompany';
import AdminCompanyList from './pages/admin/companies/AdminCompanyList';
import AdminNewDepartment from './pages/admin/departments/AdminNewDepartment';
import AdminDepartmentList from './pages/admin/departments/AdminDepartmentList';
import AdminNewNewsArticle from './pages/admin/news/AdminNewNewsArticle';
import AdminNewsArticleList from './pages/admin/news/AdminNewsArticleList';
import AdminNewNewsCategory from './pages/admin/news/categories/AdminNewNewsCategory';
import AdminNewsCategoryList from './pages/admin/news/categories/AdminNewsCategoryList';
import AdminNewBadge from './pages/admin/badges/AdminNewBadge';
import AdminBadgeList from './pages/admin/badges/AdminBadgeList';
import AdminBadgeAssignmentList from './pages/admin/badges/AdminBadgeAssignmentList';
import AdminProductCategoryList from './pages/admin/market/categories/AdminProductCategoryList';
import AdminNewProductCategory from './pages/admin/market/categories/AdminNewProductCategory';
import AdminOrder from './pages/admin/market/AdminOrder';
import AdminNewFaqCategory from './pages/admin/faqs/categories/AdminNewFaqCategory';
import AdminFaqCategoryList from './pages/admin/faqs/categories/AdminFaqCategoryList';
import AdminNewFaq from './pages/admin/faqs/AdminNewFaq';
import AdminFaqList from './pages/admin/faqs/AdminFaqList';
import AdminReferralList from './pages/admin/referrals/AdminReferralList';
import AdminUserMoodList from './pages/admin/moodtracker/AdminUserMoodList';
import AdminUserSuggestionList from './pages/admin/suggestions/UserSuggestionList';
import AdminBenefitCategoryList from './pages/admin/benefits/categories/AdminBenefitCategoryList';
import AdminNewBenefitCategory from './pages/admin/benefits/categories/AdminNewBenefitCategory';
import AdminNewBenefitPartner from './pages/admin/benefits/partners/AdminNewBenefitPartner';
import AdminBenefitPartnerList from './pages/admin/benefits/partners/AdminBenefitPartnerList';
import AdminNewEvent from './pages/admin/events/AdminNewEvent';
import AdminEventList from './pages/admin/events/AdminEventList';
import AdminEvent from './pages/admin/events/AdminEvent';
import AdminUserMoodAnalytics from './pages/admin/moodtracker/AdminUserMoodAnalytics';
import AdminBenefitList from './pages/admin/benefits/AdminBenefitList';
import AdminBenefit from './pages/admin/benefits/AdminBenefit';
import AdminNewBenefit from './pages/admin/benefits/AdminNewBenefit';
import AdminNewLearningCategory from './pages/admin/learning/categories/AdminNewLearningCategory';
import AdminLearningCategoryList from './pages/admin/learning/categories/AdminLearningCategoryList';
import AdminNewLearningAuthor from './pages/admin/learning/authors/AdminNewLearningAuthor';
import AdminLearningAuthorList from './pages/admin/learning/authors/AdminLearningAuthorList';
import AdminNewLearningSource from './pages/admin/learning/sources/AdminNewLearningSource';
import AdminLearningSourceList from './pages/admin/learning/sources/AdminLearningSourceList';
import AdminLearningItemList from './pages/admin/learning/AdminLearningItemList';
import AdminNewLearningItem from './pages/admin/learning/AdminNewLearningItem';
import AdminNewComplaintCategory from './pages/admin/whistleblowing/categories/AdminNewComplaintCategory';
import AdminComplaintCategoryList from './pages/admin/whistleblowing/categories/AdminComplaintCategoryList';
import AdminComplaintList from './pages/admin/whistleblowing/AdminComplaintList';
import AdminComplaint from './pages/admin/whistleblowing/AdminComplaint';
import StaticPage from './pages/staticPages/StaticPage';
import AdminNewStaticPage from './pages/admin/staticPages/AdminNewStaticPage';
import AdminStaticPageList from './pages/admin/staticPages/AdminStaticPageList';
import AdminJobOpportunityCategoryList from './pages/admin/recruitment/jobOpportunities/categories/AdminJobOpportunityCategoryList';
import AdminNewJobOpportunityCategory from './pages/admin/recruitment/jobOpportunities/categories/AdminNewJobOpportunityCategory';
import AdminJobOpportunity from './pages/admin/recruitment/jobOpportunities/AdminJobOpportunity';
import AdminNewJobOpportunity from './pages/admin/recruitment/jobOpportunities/AdminNewJobOpportunity';
import AdminJobOpportunityList from './pages/admin/recruitment/jobOpportunities/AdminJobOpportunityList';
import AdminJobApplication from './pages/admin/recruitment/jobApplications/AdminJobApplication';
import JobOpportunityList from './pages/recruitment/JobOpportunityList';
import AdminRecruitmentHome from './pages/admin/recruitment/AdminRecruitmentHome';
import AdminJobApplicationList from './pages/admin/recruitment/jobApplications/AdminJobApplicationList';
import AdminRecruitmentSettings from './pages/admin/recruitment/AdminRecruitmentSettings';
import AdminJobApplicant from './pages/admin/recruitment/jobApplicants/AdminJobApplicant';
import JobOpportunitySingle from './pages/recruitment/JobOpportunitySingle';
import Welcome from './pages/welcome/Welcome';
import DepartmentOrgChart from './pages/departments/DepartmentOrgChart';
import AppraisalForm from './pages/performance/appraisal/AppraisalForm';
//import Logout from './pages/Logout';
import AdminUserSignupRequestList from './pages/admin/users/signupRequests/AdminUserSignupRequestList';
import AdminNewEventCategory from './pages/admin/events/categories/AdminNewEventCategory';
import AdminEventCategoryList from './pages/admin/events/categories/AdminEventCategoryList';
import AdminNewsArticle from './pages/admin/news/AdminNewsArticle';
import AdminEmployeeAnalytics from './pages/admin/employees/AdminEmployeeAnalytics';
import AdminUserList from './pages/admin/users/AdminUserList';
import AdminSkillList from './pages/admin/employees/skills/AdminSkillList';
import AdminNewSkill from './pages/admin/employees/skills/AdminNewSkill';
import AdminNewShift from './pages/admin/shifts/AdminNewShift';
import AdminShiftList from './pages/admin/shifts/AdminShiftList';
import AdminShift from './pages/admin/shifts/AdminShift';
import AdminShiftAssignmentHome from './pages/admin/shifts/shiftAssignments/AdminShiftAssignmentHome';
import AdminEmployeeAvailabilityHome from './pages/admin/shifts/employeeAvailabilities/AdminEmployeeAvailabilityHome';
import AdminNewEmployeeAvailability from './pages/admin/shifts/employeeAvailabilities/AdminNewEmployeeAvailability';
import AdminNewDocumentCategory from './pages/admin/documents/categories/AdminNewDocumentCategory';
import AdminDocumentCategoryList from './pages/admin/documents/categories/AdminDocumentCategoryList';
import AdminDocumentList from './pages/admin/documents/AdminDocumentList';
import DocumentCategoryList from './pages/documents/DocumentCategoryList';
import DocumentCategorySingle from './pages/documents/DocumentCategorySingle';
import AdminNewDocument from './pages/admin/documents/AdminNewDocument';
import DocumentList from './pages/documents/DocumentList';
import MessageList from './pages/messages/MessageList';
import ConversationSingle from './pages/messages/ConversationSingle';
import AdminInsights from './pages/admin/insights/AdminInsights';
import FeedSearch from './pages/FeedSearch';

import { SigninCallback } from "./components/auth/SigninCallback";
import { Logout } from "./components/auth/logout";
import { LogoutCallback } from "./components/auth/logoutCallback";
import { PrivateRoute } from "./routes/privateRoute";
import { SilentRenew } from "./components/auth/silentRenew";
import { ProtectedRoute } from './routes/protectedRoute';
import { useAuth } from './providers/authProvider';
import Load from './components/Load';
import { postMessageWebView } from './utils/postMessageWebView';
import { SigninToken } from './components/auth/SigninToken';
import AdminInsightsEmployee from './pages/admin/insights/AdminInsightsEmployee';
import AdminImportBadgeAssignments from './pages/admin/badges/AdminImportBadgeAssignment';
import ScholarshipFundList from './pages/scholarships/funds/ScholarshipFundList';
import AdminNewScholarshipFund from './pages/admin/scholarships/funds/AdminNewScholarshipFund';
import AdminScholarshipFundList from './pages/admin/scholarships/funds/AdminScholarshipFundList';
import ScholarshipFundSingle from './pages/scholarships/funds/ScholarshipFundSingle';
import AdminScholarshipFund from './pages/admin/scholarships/funds/AdminScholarshipFund';
import CompanyList from './pages/companies/CompanyList';
import CompanySingle from './pages/companies/CompanySingle';
import Chat from './pages/chat/Chat';
import GroupList from './pages/groups/GroupList';
import AdminNewGroup from './pages/admin/groups/AdminNewGroup';
import AdminGroupList from './pages/admin/groups/AdminGroupList';
import GroupSingle from './pages/groups/GroupSingle';
import EventCalendar from './pages/events/EventCalendar';

let httpLink = createUploadLink({
    uri: config.GRAPHQL_API_BASE_URL,
    //credentials: 'include'
})

/*
const httpLink = createHttpLink({
    uri: config.GRAPHQL_API_BASE_URL,
});
*/

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    //const token = localStorage.getItem('token');

    const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${config.openid.AUTHORITY_URI}:${config.openid.CLIENT_ID}`))

    const access_token = oidcStorage?.access_token

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: access_token ? `Bearer ${access_token}` : null,
        }
    }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${config.openid.AUTHORITY_URI}:${config.openid.CLIENT_ID}`))
    if(!oidcStorage) {
        window.location = "/logout";
        return;
    }

    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
            console.log(errorMessage);
            toast.error(config.DEBUG_ENABLED ? message : 'Ocorreu um erro');

            if(errorMessage.includes("Message: User is not allowed to use query: Viewer")) {
                //window.location = "/"
            }
        }
      );
    }
  
    if (networkError) {
        const errorMessage = `[Network error]: ${networkError}`;
        console.log(errorMessage);
        toast.error(config.DEBUG_ENABLED ? errorMessage : 'Ocorreu um erro');
        //window.location = "/logout"
        window.location = "/logout"
    }
});

/*
const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
            PostList: {
                ...offsetLimitPagination(),
                read(existing, { args }) {
                    if (args && args.limit !== undefined && args.offset !== undefined) {
                      return existing && existing.slice(args.offset, args.offset + args.limit);
                    }
                },
            }
        },
      },
    },
});
*/

const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
            PostList: {
                ...offsetLimitPagination(),
                //keyArgs: false,
                keyArgs: ['filter'],
                merge(existing = [], incoming, { args }) {
                    // this is to find if there is filters args in query, if so, return incoming results
                    /*
                    if (args.filter && Object.entries(args.filter).find(([key, value]) => value !== null && value !== undefined)) {
                        return incoming;
                    }

                    if (!existing) {
                        return incoming;
                    }*/
                    
                    return [...existing, ...incoming];
                    /*return {
                        ...incoming,
                        data: [...(existing.data ?? []), ...(incoming.data ?? [])],
                    };*/
                },
            },
        },
      },
      Post: {
        keyFields: ["id"],
      }
      /*
      Post: {
        fields: {
          products: {
            keyArgs: false,
            merge(existing, incoming) {
              return {
                ...incoming,
                data: [...(existing?.data ?? []), ...(incoming.data ?? [])],
              };
            },
          },
        },
      },
      */
  },
  addTypename: false
});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}


const client = new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: cache, //new InMemoryCache()
    defaultOptions
})

const CREATE_TRACKING_EVENT_MUTATION = gql`
    mutation CreateTrackingEvent($app: String!, $page: String, $event: String, $customData: String) {
        CreateTrackingEvent(app: $app, page: $page, event: $event, customData: $customData) {
            id
        }
    }
`;

function App() {

    //console.log('id: ' + id)
    //console.log(roles)
    const [userRoles, setUserRoles] = useState([])
    
    /*
    console.log(isAuthenticated)*/
    const auth = useAuth();
    useEffect(() => {
        console.log('calling')
        if(auth.isAuthenticated()) {
            console.log('calling after isAuthenticated')
            auth.getUser().then(u => {
                console.log(u)
                setUserRoles(u?.profile?.roles)
                console.log(u?.profile?.roles)

                // Pass token to mobile app
                const sessionStorageKey = `oidc.user:${config.openid.AUTHORITY_URI}:${config.openid.CLIENT_ID}`;
                const authSessionStorage = window.sessionStorage.getItem(sessionStorageKey);
                postMessageWebView(sessionStorageKey, authSessionStorage);
            }).catch(error => {
                console.log(error)
            })
        }
    }, [auth])

    console.log('Company: ' + window.location.host.split('.')[0])
    console.log(auth)

    //if(!userRoles || userRoles.length == 0) return <Load/>;
    //if(!roles || roles.length == 0) return <Load/>;

    return (
        <GlobalState>
            <ApolloProvider client={client}>
                <RBACProvider roles={userRoles}>
                    <BrowserRouter basename={'/'}>
                        <ToastContainer
                        position="bottom-right"
                        hideProgressBar={true}
                        />
                        <Routes>
                            <Route exact={true} path="/signin-oidc" element={<SigninCallback />} />
                            <Route exact={true} path="/logout" element={<Logout />} />
                            <Route exact={true} path="/logout/callback" element={<LogoutCallback />} />
                            <Route exact={true} path="/silentrenew" element={<SilentRenew />} />

                            <Route exact={true} path="/signin-token" element={<SigninToken />} />

                            <Route exact path={`/`} element={<Demo/>}/>
                            
                            {false && <Route exact path={`/logout`} element={<Logout/>}/>}
                            
                            <Route exact element={<ProtectedRoute />}>
                                <Route exact path="/welcome" element={<Welcome />} />
                            </Route>

                            <Route exact path={`/home`} element={<Home/>}/>
                            <Route exact path={`/feed/hashtags/:hashtag`} element={<FeedHashtag/>}/>
                            <Route exact path={`/feed/search/:searchQuery`} element={<FeedSearch/>}/>
                            <Route exact path={`/feed/:id`} element={<PostSingle/>}/>
                            <Route exact path={`/feed`} element={<Feed/>}/>

                            <Route exact path={`/defaultbadge`} element={<Badge/>}/>
                            <Route exact path={`/defaultgroup`} element={<Group/>}/>
                            <Route exact path={`/defaultstorie`} element={<Storie/>}/>
                            <Route exact path={`/defaultemailbox`} element={<Email/>}/>
                            <Route exact path={`/defaultemailopen`} element={<Emailopen/>}/>
                            
                            <Route exact path={`/defaultvideo`} element={<Videos/>}/>
                            <Route exact path={`/defaultanalytics`} element={<Analytics/>}/>
                            
                            
                            <Route exact path={`/account`} element={<Account/>}/>
                            <Route exact path={`/account/qrcode`} element={<EmployeeQRCode/>}/>

                            <Route exact path={`/defaultmember`} element={<Member/>}/>
                            <Route exact path={`/contactinformation`} element={<Contactinfo/>}/>
                            <Route exact path={`/socialaccount`} element={<Socialaccount/>}/>
                            <Route exact path={`/password`} element={<Password/>}/>
                            <Route exact path={`/payment`} element={<Payment/>}/>
                            {false && <Route exact path={`/defaultnoti`} element={<Notification/>}/> }
                            <Route exact path={`/helpbox`} element={<Helpbox/>}/>
                            <Route exact path={`/login`} element={<Login/>}/>
                            <Route exact path={`/register`} element={<Register/>}/>
                            <Route exact path={`/forgot`} element={<Forgot/>}/>
                            <Route exact path={`/notfound`} element={<Notfound/>}/>

                            <Route exact path={`/shop1`} element={<ShopOne/>}/>
                            <Route exact path={`/shop2`} element={<ShopTwo/>}/>
                            <Route exact path={`/shop3`} element={<ShopThree/>}/>
                            <Route exact path={`/singleproduct`} element={<Singleproduct/>}/>
                            <Route exact path={`/cart`} element={<CartDemo/>}/>
                            <Route exact path={`/checkout`} element={<CheckoutDemo/>}/>
                            <Route exact path={`/defaultmessage`} element={<Chat/>}/>
                            <Route exact path={`/defaultlive`} element={<Live/>}/>       
                            
                            <Route exact path={`/defaultjob`} element={<Job/>}/>
                            <Route exact path={`/defaultevent`} element={<Event/>}/>
                            <Route exact path={`/defaulthotel`} element={<Hotel/>}/>  
                            <Route exact path={`/grouppage`} element={<Grouppage/>}/>
                            <Route exact path={`/userpage`} element={<Userpage/>}/>
                            <Route exact path={`/authorpage`} element={<Authorpage/>}/>  
                            <Route exact path={`/comingsoon`} element={<Comingsoon/>}/>  
                            <Route exact path={`/defaulthoteldetails`} element={<Hotelsingle/>}/>

                            <Route exact path={`/settings`} element={<Settings/>}/>
                            <Route exact path={`/notifications`} element={<Notification/>}/>

                            <Route exact path={`/benefits/:id`} element={<BenefitSingle/>}/>
                            <Route exact path={`/benefits`} element={<BenefitList/>}/>

                            <Route exact path={`/employees/orgChart`} element={<EmployeeOrgChart/>}/>
                            <Route exact path={`/employees/:id/badges`} element={<EmployeeBadgeList/>}/>
                            <Route exact path={`/employees/:id`} element={<EmployeeSingle/>}/>
                            <Route exact path={`/employees`} element={<EmployeeList/>}/>

                            <Route exact path={`/departments/orgChart`} element={<DepartmentOrgChart/>}/>

                            <Route exact path={`/birthdays`} element={<BirthdayList/>}/>

                            <Route exact path={`/badges`} element={<BadgeRanking/>}/>
                            <Route exact path={`/badges/give/:employeeId`} element={<GiveBadge/>}/>
                            <Route exact path={`/badges/give`} element={<GiveBadge/>}/>
                            <Route exact path={`/badges/interactions`} element={<BadgeInteractionsChart/>}/>

                            <Route exact path={`/feedback`} element={<EmployeeFeedbackList/>}/>
                            <Route exact path={`/feedback/give/:employeeId`} element={<GiveFeedback/>}/>
                            <Route exact path={`/feedback/give`} element={<GiveFeedback/>}/>

                            <Route exact path={`/feedback/requests`} element={<FeedbackRequestList/>}/>
                            <Route exact path={`/feedback/requests/new/:employeeId`} element={<CreateFeedbackRequest/>}/>
                            <Route exact path={`/feedback/requests/new`} element={<CreateFeedbackRequest/>}/>
                            
                            <Route exact path={`/referrals`} element={<EmployeeReferralsList/>}/>
                            <Route exact path={`/referrals/new`} element={<ReferFriend/>}/>

                            <Route exact path={`/moodtracker/calendar`} element={<MoodTrackerCalendar/>}/>

                            <Route exact path={`/learning`} element={<LearningList/>}/>
                            <Route exact path={`/learning/items/:id`} element={<LearningItemSingle/>}/>

                            <Route exact path={`/suggestions/new`} element={<CreateSuggestion/>}/>

                            <Route exact path={`/news`} element={<NewsArticleList/>}/>
                            <Route exact path={`/news/:id`} element={<NewsArticleSingle/>}/>
                            
                            <Route exact path={`/events`} element={<EventList/>}/>
                            <Route exact path={`/events/calendar`} element={<EventCalendar/>}/>
                            <Route exact path={`/events/:id`} element={<EventSingle/>}/>

                            <Route exact path={`/okr/objectives`} element={<ObjectiveList/>}/>
                            <Route exact path={`/okr/objectives/new`} element={<CreateObjective/>}/>

                            <Route exact path={`/okr/keyresults/new/:objectiveId`} element={<CreateKeyResult/>}/>
                            <Route exact path={`/okr/checkin/:keyResultId`} element={<CreateKeyResultCheckin/>}/>

                            <Route exact path={`/whistleblowing/complaints`} element={<ComplaintsHome/>}/>
                            <Route exact path={`/whistleblowing/complaints/new`} element={<CreateComplaint/>}/>
                            <Route exact path={`/whistleblowing/complaints/follow-up`} element={<FollowupComplaint/>}/>

                            <Route exact path={`/wallet`} element={<Wallet/>}/>

                            <Route exact path={`/market`} element={<MarketHome/>}/>
                            <Route exact path={`/market/products/:id`} element={<ProductSingle/>}/>
                            <Route exact path={`/market/checkout`} element={<Checkout/>}/>
                            <Route exact path={`/market/cart`} element={<Cart/>}/>
                            <Route exact path={`/market/orders`} element={<OrdersList/>}/>

                            <Route exact path={`/faqs`} element={<FAQs/>}/>

                            <Route exact path={`/pages/:path`} element={<StaticPage/>}/>

                            <Route exact path={`/recruitment/jobOpportunities`} element={<JobOpportunityList/>}/>
                            <Route exact path={`/recruitment/jobOpportunities/:id`} element={<JobOpportunitySingle/>}/>
                            
                            <Route exact path={`/performance/appraisal/form/:id`} element={<AppraisalForm/>}/>

                            <Route exact path={`/documents`} element={<DocumentCategoryList/>}/>
                            <Route exact path={`/documents/search`} element={<DocumentList/>}/>
                            <Route exact path={`/documents/categories/:id`} element={<DocumentCategorySingle/>}/>

                            <Route exact path={`/messages`} element={<MessageList/>}/>
                            <Route exact path={`/messages/:id`} element={<ConversationSingle/>}/>

                            <Route exact path={`/scholarships/funds`} element={<ScholarshipFundList/>}/>
                            <Route exact path={`/scholarships/funds/:id`} element={<ScholarshipFundSingle/>}/>
                            
                            <Route exact path={`/companies`} element={<CompanyList/>}/>
                            <Route exact path={`/companies/:id`} element={<CompanySingle/>}/>
                            
                            <Route exact path={`/chat`} element={<Chat/>}/>

                            <Route exact path={`/groups`} element={<GroupList/>}/>
                            <Route exact path={`/groups/:id`} element={<GroupSingle/>}/>

                            <Route exact path={`/admin`} element={<AdminHome/>}/>

                            <Route exact path={`/admin/users/signupRequests`} element={<AdminUserSignupRequestList/>}/>
                            <Route exact path={`/admin/users`} element={<AdminUserList/>}/>

                            <Route exact path={`/admin/employees`} element={<AdminEmployeeList/>}/>
                            <Route exact path={`/admin/employees/analytics`} element={<AdminEmployeeAnalytics/>}/>
                            <Route exact path={`/admin/employees/import`} element={<AdminImportEmployees/>}/>
                            <Route exact path={`/admin/employees/new`} element={<AdminNewEmployee/>}/>
                            <Route exact path={`/admin/employees/edit/:id`} element={<AdminNewEmployee/>}/>
                            <Route exact path={`/admin/employees/:id`} element={<AdminEmployee/>}/>

                            <Route exact path={`/admin/employees/skills`} element={<AdminSkillList/>}/>
                            <Route exact path={`/admin/employees/skills/new`} element={<AdminNewSkill/>}/>
                            <Route exact path={`/admin/employees/skills/edit/:id`} element={<AdminNewSkill/>}/>
                            
                            <Route exact path={`/admin/training/trainingActions`} element={<AdminTrainingActionList/>}/>
                            <Route exact path={`/admin/training/trainingActions/new`} element={<AdminNewTrainingAction/>}/>
                            <Route exact path={`/admin/training/trainingActions/edit/:id`} element={<AdminNewTrainingAction/>}/>
                            <Route exact path={`/admin/training/trainingActions/:id`} element={<AdminTrainingAction/>}/>

                            <Route exact path={`/admin/training/trainingEntities`} element={<AdminTrainingEntityList/>}/>
                            <Route exact path={`/admin/training/trainingEntities/new`} element={<AdminNewTrainingEntity/>}/>
                            <Route exact path={`/admin/training/trainingEntities/edit/:id`} element={<AdminNewTrainingEntity/>}/>
                            <Route exact path={`/admin/training/trainingEntities/:id`} element={<AdminTrainingEntity/>}/>

                            <Route exact path={`/admin/training/trainers/new`} element={<AdminNewTrainer/>}/>
                            <Route exact path={`/admin/training/trainers/edit/:id`} element={<AdminNewTrainer/>}/>

                            <Route exact path={`/admin/quickLinks`} element={<AdminQuickLinkList/>}/>
                            <Route exact path={`/admin/quickLinks/new`} element={<AdminNewQuickLink/>}/>
                            <Route exact path={`/admin/quickLinks/edit/:id`} element={<AdminNewQuickLink/>}/>

                            <Route exact path={`/admin/market/products`} element={<AdminProductList/>}/>
                            <Route exact path={`/admin/market/products/new`} element={<AdminNewProduct/>}/>
                            <Route exact path={`/admin/market/products/edit/:id`} element={<AdminNewProduct/>}/>
                            <Route exact path={`/admin/market/orders`} element={<AdminOrderList/>}/>
                            <Route exact path={`/admin/market/orders/:id`} element={<AdminOrder/>}/>

                            <Route exact path={`/admin/market/categories`} element={<AdminProductCategoryList/>}/>
                            <Route exact path={`/admin/market/categories/new`} element={<AdminNewProductCategory/>}/>
                            <Route exact path={`/admin/market/categories/edit/:id`} element={<AdminNewProductCategory/>}/>

                            <Route exact path={`/admin/companies/new`} element={<AdminNewCompany/>}/>
                            <Route exact path={`/admin/companies/edit/:id`} element={<AdminNewCompany/>}/>
                            <Route exact path={`/admin/companies`} element={<AdminCompanyList/>}/>

                            <Route exact path={`/admin/departments/new`} element={<AdminNewDepartment/>}/>
                            <Route exact path={`/admin/departments/edit/:id`} element={<AdminNewDepartment/>}/>
                            <Route exact path={`/admin/departments`} element={<AdminDepartmentList/>}/>

                            <Route exact path={`/admin/news/new`} element={<AdminNewNewsArticle/>}/>
                            <Route exact path={`/admin/news/edit/:id`} element={<AdminNewNewsArticle/>}/>
                            <Route exact path={`/admin/news`} element={<AdminNewsArticleList/>}/>
                            <Route exact path={`/admin/news/:id`} element={<AdminNewsArticle/>}/>

                            <Route exact path={`/admin/news/categories/new`} element={<AdminNewNewsCategory/>}/>
                            <Route exact path={`/admin/news/categories/edit/:id`} element={<AdminNewNewsCategory/>}/>
                            <Route exact path={`/admin/news/categories`} element={<AdminNewsCategoryList/>}/>

                            <Route exact path={`/admin/badges/new`} element={<AdminNewBadge/>}/>
                            <Route exact path={`/admin/badges/edit/:id`} element={<AdminNewBadge/>}/>
                            <Route exact path={`/admin/badges`} element={<AdminBadgeList/>}/>

                            <Route exact path={`/admin/badges/assignments`} element={<AdminBadgeAssignmentList/>}/>
                            <Route exact path={'/admin/badges/assignments/import'} element={<AdminImportBadgeAssignments/>}/>

                            <Route exact path={`/admin/faqs/new`} element={<AdminNewFaq/>}/>
                            <Route exact path={`/admin/faqs/edit/:id`} element={<AdminNewFaq/>}/>
                            <Route exact path={`/admin/faqs`} element={<AdminFaqList/>}/>

                            <Route exact path={`/admin/faqs/categories/new`} element={<AdminNewFaqCategory/>}/>
                            <Route exact path={`/admin/faqs/categories/edit/:id`} element={<AdminNewFaqCategory/>}/>
                            <Route exact path={`/admin/faqs/categories`} element={<AdminFaqCategoryList/>}/>

                            <Route exact path={`/admin/referrals`} element={<AdminReferralList/>}/>

                            <Route exact path={`/admin/moodtracker/usermoods`} element={<AdminUserMoodList/>}/>
                            <Route exact path={`/admin/moodtracker`} element={<AdminUserMoodAnalytics/>}/>

                            <Route exact path={`/admin/suggestions`} element={<AdminUserSuggestionList/>}/>

                            <Route exact path={`/admin/benefits/new`} element={<AdminNewBenefit/>}/>
                            <Route exact path={`/admin/benefits/edit/:id`} element={<AdminNewBenefit/>}/>
                            <Route exact path={`/admin/benefits`} element={<AdminBenefitList/>}/>
                            <Route exact path={`/admin/benefits/:id`} element={<AdminBenefit/>}/>

                            <Route exact path={`/admin/benefits/categories/new`} element={<AdminNewBenefitCategory/>}/>
                            <Route exact path={`/admin/benefits/categories/edit/:id`} element={<AdminNewBenefitCategory/>}/>
                            <Route exact path={`/admin/benefits/categories`} element={<AdminBenefitCategoryList/>}/>

                            <Route exact path={`/admin/benefits/partners/new`} element={<AdminNewBenefitPartner/>}/>
                            <Route exact path={`/admin/benefits/partners/edit/:id`} element={<AdminNewBenefitPartner/>}/>
                            <Route exact path={`/admin/benefits/partners`} element={<AdminBenefitPartnerList/>}/>

                            <Route exact path={`/admin/events/new`} element={<AdminNewEvent/>}/>
                            <Route exact path={`/admin/events/edit/:id`} element={<AdminNewEvent/>}/>
                            <Route exact path={`/admin/events`} element={<AdminEventList/>}/>
                            <Route exact path={`/admin/events/:id`} element={<AdminEvent/>}/>

                            <Route exact path={`/admin/events/categories/new`} element={<AdminNewEventCategory/>}/>
                            <Route exact path={`/admin/events/categories/edit/:id`} element={<AdminNewEventCategory/>}/>
                            <Route exact path={`/admin/events/categories`} element={<AdminEventCategoryList/>}/>

                            <Route exact path={`/admin/learning/new`} element={<AdminNewLearningItem/>}/>
                            <Route exact path={`/admin/learning/edit/:id`} element={<AdminNewLearningItem/>}/>
                            <Route exact path={`/admin/learning`} element={<AdminLearningItemList/>}/>

                            <Route exact path={`/admin/learning/categories/new`} element={<AdminNewLearningCategory/>}/>
                            <Route exact path={`/admin/learning/categories/edit/:id`} element={<AdminNewLearningCategory/>}/>
                            <Route exact path={`/admin/learning/categories`} element={<AdminLearningCategoryList/>}/>

                            <Route exact path={`/admin/learning/authors/new`} element={<AdminNewLearningAuthor/>}/>
                            <Route exact path={`/admin/learning/authors/edit/:id`} element={<AdminNewLearningAuthor/>}/>
                            <Route exact path={`/admin/learning/authors`} element={<AdminLearningAuthorList/>}/>

                            <Route exact path={`/admin/learning/sources/new`} element={<AdminNewLearningSource/>}/>
                            <Route exact path={`/admin/learning/sources/edit/:id`} element={<AdminNewLearningSource/>}/>
                            <Route exact path={`/admin/learning/sources`} element={<AdminLearningSourceList/>}/>

                            <Route exact path={`/admin/whistleblowing/categories/new`} element={<AdminNewComplaintCategory/>}/>
                            <Route exact path={`/admin/whistleblowing/categories/edit/:id`} element={<AdminNewComplaintCategory/>}/>
                            <Route exact path={`/admin/whistleblowing/categories`} element={<AdminComplaintCategoryList/>}/>

                            <Route exact path={`/admin/whistleblowing/complaints/:id`} element={<AdminComplaint/>}/>
                            <Route exact path={`/admin/whistleblowing/complaints`} element={<AdminComplaintList/>}/>

                            <Route exact path={`/admin/staticPages/new`} element={<AdminNewStaticPage/>}/>
                            <Route exact path={`/admin/staticPages/edit/:id`} element={<AdminNewStaticPage/>}/>
                            <Route exact path={`/admin/staticPages`} element={<AdminStaticPageList/>}/>

                            <Route exact path={`/admin/recruitment`} element={<AdminRecruitmentHome/>}/>
                            <Route exact path={`/admin/recruitment/settings`} element={<AdminRecruitmentSettings/>}/>

                            <Route exact path={`/admin/recruitment/jobOpportunities/categories/new`} element={<AdminNewJobOpportunityCategory/>}/>
                            <Route exact path={`/admin/recruitment/jobOpportunities/categories/edit/:id`} element={<AdminNewJobOpportunityCategory/>}/>
                            <Route exact path={`/admin/recruitment/jobOpportunities/categories`} element={<AdminJobOpportunityCategoryList/>}/>

                            <Route exact path={`/admin/recruitment/jobOpportunities/new`} element={<AdminNewJobOpportunity/>}/>
                            <Route exact path={`/admin/recruitment/jobOpportunities/edit/:id`} element={<AdminNewJobOpportunity/>}/>
                            <Route exact path={`/admin/recruitment/jobOpportunities`} element={<AdminJobOpportunityList/>}/>
                            <Route exact path={`/admin/recruitment/jobOpportunities/:id`} element={<AdminJobOpportunity/>}/>

                            <Route exact path={`/admin/recruitment/jobApplications`} element={<AdminJobApplicationList/>}/>
                            <Route exact path={`/admin/recruitment/jobApplications/:id`} element={<AdminJobApplication/>}/>

                            <Route exact path={`/admin/recruitment/jobApplicants/:id`} element={<AdminJobApplicant/>}/>

                            <Route exact path={`/admin/shifts/new`} element={<AdminNewShift/>}/>
                            <Route exact path={`/admin/shifts/edit/:id`} element={<AdminNewShift/>}/>
                            <Route exact path={`/admin/shifts`} element={<AdminShiftList/>}/>
                            <Route exact path={`/admin/shifts/:id`} element={<AdminShift/>}/>

                            <Route exact path={`/admin/shifts/assignments`} element={<AdminShiftAssignmentHome/>}/>

                            <Route exact path={`/admin/shifts/availability`} element={<AdminEmployeeAvailabilityHome/>}/>
                            <Route exact path={`/admin/shifts/availability/new`} element={<AdminNewEmployeeAvailability/>}/>

                            <Route exact path={`/admin/documents/categories/new`} element={<AdminNewDocumentCategory/>}/>
                            <Route exact path={`/admin/documents/categories/edit/:id`} element={<AdminNewDocumentCategory/>}/>
                            <Route exact path={`/admin/documents/categories`} element={<AdminDocumentCategoryList/>}/>

                            <Route exact path={`/admin/documents/new`} element={<AdminNewDocument/>}/>
                            <Route exact path={`/admin/documents`} element={<AdminDocumentList/>}/>

                            <Route exact path={`/admin/scholarships/funds/new`} element={<AdminNewScholarshipFund/>}/>
                            <Route exact path={`/admin/scholarships/funds/edit/:id`} element={<AdminNewScholarshipFund/>}/>
                            <Route exact path={`/admin/scholarships/funds`} element={<AdminScholarshipFundList/>}/>
                            <Route exact path={`/admin/scholarships/funds/:id`} element={<AdminScholarshipFund/>}/>

                            <Route exact path={`/admin/groups/new`} element={<AdminNewGroup/>}/>
                            <Route exact path={`/admin/groups/edit/:id`} element={<AdminNewGroup/>}/>
                            <Route exact path={`/admin/groups`} element={<AdminGroupList/>}/>

                            <Route exact path={`/admin/insights`} element={<AdminInsights/>}/>
                            <Route exact path={`/admin/insights/employees`} element={<AdminInsightsEmployee/>}/>

                            <Route path='*' element={<Notfound/>}/>
                        </Routes>
                    </BrowserRouter>
                </RBACProvider>
            </ApolloProvider>
        </GlobalState>
    );
}

//export default App;

export const TrackedApp = track(
    // app-level tracking data
    { app: "empathize-frontend" },
  
    // top-level options
    {
      // custom dispatch to console.log in addition to pushing to dataLayer[]
      dispatch: data => {
        //console.log("Tracking:", data);
        (window.dataLayer = window.dataLayer || []).push(data);
        // here is where you add your custom analytics call

        client.mutate({
            mutation: CREATE_TRACKING_EVENT_MUTATION,
            variables: data
        }).then(() => null);
      }
    }
  )(App);
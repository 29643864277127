import React,{Component} from 'react';
import { Link } from 'react-router-dom';

import 'react-image-lightbox/style.css';
import { config } from '../config';
import { getImageUrl } from '../utils';



const TabOne = [
    {
        image: '01',
        bigImage: 'assets/images/hotel.png',
    },
    {
        image: '02',
        bigImage: 'assets/images/hotel.png',
    },
    {
        image: '03',
        bigImage: 'assets/images/hotel.png',
    },
    {
        image: '04',
        bigImage: 'assets/images/hotel.png',
    },
    {
        image: '05',
        bigImage: 'assets/images/hotel.png',
    },
    {
        image: '06',
        bigImage: 'assets/images/hotel.png',
    },
]

function BadgesWidget(props) {

    if(!props.profile || !props.badges) {
        return null;
    }

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
            <div className="card-body d-flex align-items-center  p-4">
                <h4 className="fw-700 mb-0 font-xssss text-grey-900">Aplausos</h4>
                <Link to={`/employees/${props.profile.id}/badges`} className="fw-600 ms-auto font-xssss text-success">Ver todos ({props.total})</Link>
            </div>
            <div className="card-body d-block pt-0 pb-2">
                <div className="row ps-3 pe-3">
                    {props.badges.map((value , index) => (
                        <div className="col-6 mb-1 p-1" key={index}>
                            <div>
                                <Link to={`/employees/${props.profile.id}/badges?id=${value.id}`}>
                                    <img src={getImageUrl(value.badge.thumbnail, '/assets/images/abstract-bg-green.png')} alt={value.badge.name} className="img-fluid aspect-ratio-675-500 rounded-3 w-100" style={{ objectFit: "contain" }}/>
                                </Link>
                            </div>
                        
                        </div>
                    ))}
                </div>
            </div>
            <div className="card-body d-block w-100 pt-0">
                <Link to={`/employees/${props.profile.id}/badges`} className="p-2 lh-28 w-100 d-block bg-grey text-grey-800 text-center font-xssss fw-700 rounded-xl"><i className="feather-external-link font-xss me-2"></i> Mais</Link>
            </div>
        </div>
    );
}

export default BadgesWidget;
import React, { Fragment, useState } from "react";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';
import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../components/Pagetitle";
import { getImageUrl } from "../../../utils";
import Moment from "react-moment";
import { DATE_FILTERS } from "../../../config";
import { Link } from "react-router-dom";

const GET_QUERY = gql`
    query Get($dateFilter: [inp_DateTimeFilterCondition]) {
        BadgeAssignmentList(orderBy: {createdDate: DESC}, filter: { createdDate: $dateFilter }) {
            id
            badge {
                id
                name
                thumbnail
            }
            sender {
                id
                name
                picture
                user {
                    id
                    picture
                }
            }
            receiver {
                id
                name
                picture
                user {
                    id
                    picture
                }
            }
            message
            createdDate
        }
    }
`;

const GET_LISTS_QUERY = gql`
    query GetLists {
        BadgeList(orderBy: { name: ASC }) {
            id
            name
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_BadgeAssignment(id: $id)
    }
`;

function AdminBadgeAssignmentList() {

    const [selectedBadge, setSelectedBadge] = useState("")
    const [selectedDateFilter, setSelectedDateFilter] = useState({})
    
    const { loading, error, data, refetch } = useQuery(GET_QUERY, { variables: { dateFilter: selectedDateFilter }});
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);
    const [deleteMutation, { deleteMutationData }] = useMutation(DELETE_MUTATION);

    const deleteResult = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar o aplauso atribuído?')) {
            const deleteResult = await deleteMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('Aplauso eliminado com sucesso!')
            }
        }
    }

    const setDateFilter = (id) => {
        setSelectedDateFilter(DATE_FILTERS.filter(e => e.id === id)[0].value)
    }

    const results = (data && data.BadgeAssignmentList
        .filter(a => selectedBadge === "" || a.badge.id === selectedBadge)) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="Aplausos > Atribuídos"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ results.length } resultados</h4>
                                            <div className="float-right">
                                                <Link to="/admin/badges/assignments/import" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Importar</Link>

                                                <select className="searchCat sort" onChange={e => setSelectedBadge(e.target.value)}>
                                                    <option value="">Todos</option>
                                                    { listsData?.BadgeList?.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                                </select>
                                                <select className="searchCat sort ms-1 me-1" onChange={e => setDateFilter(e.target.value)}>
                                                    <option value="">Data</option>
                                                    { DATE_FILTERS.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                                </select>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4 d-none d-lg-table-cell">&nbsp;</th>
                                                            <th className="border-0 p-4">Tipo</th>
                                                            <th className="border-0 p-4">Atribuído por</th>
                                                            <th className="border-0 p-4">Destinatário</th>
                                                            <th className="border-0 p-4">Mensagem</th>
                                                            <th className="border-0 p-4">Data</th>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {results.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="product-thumbnail text-center ps-0 d-none d-lg-table-cell">
                                                                <img src={getImageUrl(value.badge.thumbnail)} className="w75 rounded-3" />
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.badge.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.sender.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.receiver.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.message}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss"><Moment format="DD/MM/YYYY HH:mm">{value.createdDate}</Moment></span>
                                                            </td>
                                                            <td className="text-right">
                                                                <a role="button" onClick={() => deleteResult(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminBadgeAssignmentList;
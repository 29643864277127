import React, { Component , Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import Moment from 'react-moment';

import { InView } from "react-intersection-observer";

import moment from "moment";

import { toast } from "react-toastify";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';

import Profiledetail from '../../components/Profiledetail';
import BadgesWidget from '../../components/BadgesWidget';
import ProfilecardThree from '../../components/ProfilecardThree';
import Createpost from '../../components/Createpost';
import Events from '../../components/Events';
import Postview from '../../components/Postview';
import Load from '../../components/Load';
import { getImageUrl } from "../../utils";
import { config } from "../../config";
import track, { useTracking } from "react-tracking";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
        }
    }
`;

const GET_EMPLOYEE_QUERY = gql`
    query GetEmployee($id: String!) {
        EmployeeById(id: $id) {
            id
            name
            birthdate
            email
            jobTitle
            phone
            mobile
            mobilePersonal
            whatsapp
            bio
            picture
            hireDate
            terminationDate
            coverImage
            website
            linkedinUrl
            company {
                id
                name
            }
            user {
                id
                picture
            }
        }
    }
`;

const GET_EMPLOYEE_POSTS_QUERY = gql`
    query GetEmployeePosts($filter: [inp_PostFilterCondition], $limit: Int, $offset: Int) {
        PostList(filter: $filter, orderBy: {createdDate: DESC}, limit: $limit, offset: $offset) {
            id
            title
            content
            type
            isPinned
            allowComments
            user {
                id
                name
                picture
                employee {
                    id
                    name
                    jobTitle
                    birthdate
                    user {
                        id
                        picture
                    }
                }
            }
            thumbnail
            group {
                id
                name
            }
            employee {
                id
                name
                jobTitle
                birthdate
            }
            event {
                id
                title
                startDate
                endDate
                location
                thumbnail
                createdDate
            }
            mediaGallery {
                id
            }
            survey {
                id
            }
            createdDate
            comments {
                id
                content
                user {
                    id
                    name
                    picture
                    employee {
                        id
                        name
                        jobTitle
                        birthdate
                    }
                }
                createdDate
                replies {
                    id
                    content
                    user {
                        id
                        name
                        picture
                        employee {
                            id
                            name
                            jobTitle
                            birthdate
                        }
                    }
                    createdDate
                }
            }
            reactions {
                id
                type
                user {
                    id
                }
            }
            attachments {
                id
                name
                file
            }
        }
    }
`;


const GET_BADGE_ASSIGNMENTS_QUERY = gql`
    query GetBadgeAssignments($employeeId: UUID) {
        BadgeAssignmentCount(filter: { receiver: { id: { _eq: $employeeId } } })
        BadgeAssignmentList(filter: { receiver: { id: { _eq: $employeeId } } }, orderBy: { createdDate: DESC }, limit: 6) {
            id
            badge {
                id
                name
                description
                thumbnail
                points
            }
            sender {
                id
                name
                user {
                    id
                    picture
                }
            }
            createdDate
        }
    }
`;

const UPSERT_POST_REACTION_MUTATION = gql`
    mutation UpsertPostReaction($id: UUID, $type: ReactionType, $userId: UUID) {
        upsert_PostReaction(postReaction:{ post: { id: $id }, type: $type, user: { id: $userId } }) {
            id
        }
    }
`;

const DELETE_POST_REACTION_MUTATION = gql`
    mutation DeletePostReaction($id: String!) {
        delete_PostReaction(id: $id)
    }
`;

const UPSERT_POST_COMMENT_MUTATION = gql`
    mutation UpsertPostComment($id: UUID, $content: String, $userId: UUID) {
        upsert_PostComment(postComment: {
            post: { id: $id },
            user: { id: $userId },
            content: $content
        }) {
            id
        }
    }
`;

const UPSERT_COMMENT_REPLY_MUTATION = gql`
    mutation UpsertCommentReply($id: UUID, $content: String, $userId: UUID) {
        upsert_CommentReply(commentReply: {
            parentComment: { id: $id },
            user: { id: $userId },
            content: $content
        }) {
            id
        }
    }
`;

const UPSERT_EMPLOYEE_COVER_IMAGE_MUTATION = gql`
    mutation UpsertEmployee($id: UUID!, $coverImage: Upload) {
        upsert_Employee(employee: { id: $id, coverImage: $coverImage }) {
            id
        }
    }
`;

function EmployeeSingle() {

    let { id } = useParams();

    const { trackEvent } = useTracking();

    const { loading, error, data, refetch } = useQuery(GET_EMPLOYEE_QUERY, { variables: { id: id }});
    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { data: postsData, loading: postsLoading, fetchMore: postsFetchMore, error: postsError, refetch: postsRefetch } = useQuery(GET_EMPLOYEE_POSTS_QUERY, {
        //fetchPolicy: "cache-and-network",
        //nextFetchPolicy: "cache-first",
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-and-network",
        variables: {
            filter: {OR: [
                { employee: { id: {_eq: id } } },
                data && data.EmployeeById && data.EmployeeById.user ? { user: { id: { _eq: data.EmployeeById.user.id } } } : { },
            ]},
            limit: 10,
            offset: 0
        },
        skip: !id && !data?.EmployeeById?.user?.id
    });
    const { data: badgeAssignmentsData, loading: badgeAssignmentsLoading, error: badgeAssignmentsError } = useQuery(GET_BADGE_ASSIGNMENTS_QUERY, {
        variables: { employeeId: id }
    });

    const [upsertPostReactionMutation, { upsertPostReactionMutationData }] = useMutation(UPSERT_POST_REACTION_MUTATION);
    const [deletePostReactionMutation, { deletePostReactionMutationData }] = useMutation(DELETE_POST_REACTION_MUTATION);

    const [upsertPostCommentMutation, { upsertPostCommentMutationData }] = useMutation(UPSERT_POST_COMMENT_MUTATION);
    const [upsertCommentReplyMutation, { upsertCommentReplyMutationData }] = useMutation(UPSERT_COMMENT_REPLY_MUTATION);

    const [upsertEmployeeCoverImageMutation, { upsertEmployeeCoverImageMutationData }] = useMutation(UPSERT_EMPLOYEE_COVER_IMAGE_MUTATION);

    useEffect(() => {
        trackEvent({ event: "view", customData: id });
    }, [])

    const handleLike = async (id) => {
        await upsertPostReactionMutation({ 
            variables: {
                id,
                type: 'LIKE',
                userId: viewerData.Viewer.id
            },
        })
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }

    const handleLove = async (id) => {
        await upsertPostReactionMutation({ 
            variables: {
                id,
                type: 'LOVE',
                userId: viewerData.Viewer.id
            },
        })
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }

    const handleDeleteReaction = async (reactionId) => {
        await deletePostReactionMutation({ 
            variables: {
                id: reactionId
            },
        })
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }

    const handleCreateComment = async (id, content) => {
        await upsertPostCommentMutation({ 
            variables: {
                id,
                content,
                userId: viewerData.Viewer.id
            },
        })
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }

    const handleCreateCommentReply = async (id, content) => {
        await upsertCommentReplyMutation({ 
            variables: {
                id,
                content,
                userId: viewerData.Viewer.id
            },
        })
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }

    const handlePostUpdated = async () => {
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }
    
    const handleUpdateCoverImage = async (file) => {
        const upsertResult = await upsertEmployeeCoverImageMutation({ 
            variables: {
                id: data.EmployeeById.id,
                coverImage: file,
            },
        });

        if(upsertResult.data) {
            toast.success('Imagem atualizada!');
            refetch();
        } else {
            toast.success('Ocorreu um erro ao atualizar a imagem');
        }

    }

    const employee = data && data.EmployeeById;
    const badges = badgeAssignmentsData && badgeAssignmentsData.BadgeAssignmentList || [];
    const badgesCount = badgeAssignmentsData && badgeAssignmentsData.BadgeAssignmentCount;

    const isCurrentUser = employee && employee?.user?.id === viewerData?.Viewer?.id;
    //console.log(postsData)
    
    return (
        <Fragment> 
            <Header />
            <Leftnav />

            { loading && <LoadingOverlayWrapper/> }

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12 mb-3">
                                <ProfilecardThree profile={employee} isCurrentUser={isCurrentUser} handleUpdateCoverImage={handleUpdateCoverImage} />
                            </div>
                            <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                                <Profiledetail profile={employee} isCurrentUser={isCurrentUser} />
                                <BadgesWidget profile={employee} badges={badges} total={badgesCount} />
                                { config.FEATURES_PREVIEW_ENABLED && <Events /> }

                                { false && <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3">
                                    <div className="card-body p-3 border-0">
                                        <div className="row">
                                            <div className="col-3">
                                                <div className="chart-container w50 h50">
                                                    <div className="chart position-relative" data-percent="78" data-bar-color="#a7d212">
                                                        <span className="percent fw-700 font-xsss" data-after="%">78</span>
                                                    <canvas height="50" width="50"></canvas></div>
                                                </div>
                                            </div>
                                            <div className="col-8 ps-1">
                                                <h4 className="font-xsss d-block fw-700 mt-2 mb-0">Advanced Python Sass <span className="float-right mt-2 font-xsssss text-grey-500">87%</span></h4>
                                                <p className="font-xssss fw-600 text-grey-500 lh-26 mb-0">Designer</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            <div className="col-xl-8 col-xxl-9 col-lg-8">
                                { false && <Createpost /> }
                                
                                {postsData && postsData.PostList && postsData.PostList.length === 0 && <span className="d-block font-xssss fw-500 p-2 mt-3 mb-3 text-grey-500 text-center">Ainda não existem publicações</span>}
                                
                                {postsData && postsData.PostList && postsData.PostList.map((value , index) => (
                                    <Postview key={value.id} id={value.id} type={value.type} postvideo="" title={value.title} postimage={getImageUrl(value.thumbnail)} avater={getImageUrl(value.user.picture, '/assets/images/user.png')} user={value.user} time={value.createdDate} des={value.content} pin={value.isPinned} allowComments={value.allowComments} comments={value.comments} attachments={value.attachments} handleCreateComment={handleCreateComment} handleCreateReply={handleCreateCommentReply} reactions={value.reactions} handleLike={() => handleLike(value.id)} handleLove={() => handleLove(value.id)} handleDeleteReaction={handleDeleteReaction} currentUser={viewerData && viewerData.Viewer.id} employee={value.employee} event={value.event} newsArticle={value.newsArticle} onPostUpdated={handlePostUpdated} galleryId={value?.mediaGallery?.id} surveyId={value?.survey?.id} group={value?.group} />
                                ))}
                                { postsLoading && <Load /> }
                                {postsData && (
                                    <InView
                                    onChange={async (inView) => {
                                        const currentLength = postsData.PostList.length || 0;
                                        if (inView) {
                                            console.log('postsFetchMore')
                                            await postsFetchMore({
                                                variables: {
                                                    filter: { OR: [
                                                        { employee: { id: {_eq: id } } },
                                                        data && data.EmployeeById && data.EmployeeById.user ? { user: { id: { _eq: data.EmployeeById.user.id } } } : { },
                                                    ]},
                                                    offset: currentLength,
                                                    limit: 10,
                                                },
                                            });
                                        }
                                    }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 

        </Fragment>
    );
}

//export default EmployeeSingle;

export default track(
    { page: "Employee" },
    //{ dispatchOnMount: contextData => ({ event: "pageLoaded" }) }
)(EmployeeSingle);
import React, { Fragment } from "react";
import { useNavigate } from 'react-router';

import { useQuery, gql } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import AdminHeader from '../../components/AdminHeader';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';
import { config } from "../../config";
import Pagetitle from "../../components/Pagetitle";
import Forbidden from "../Forbidden";

const GET_COUNTERS_QUERY = gql`
    query Get {
        EmployeeCount(filter: { isActive: { _eq: true } })
        PostCount
        Comment_Count
        Order_Count
    }
`;

const featureList = [
    {
        title: 'Colaboradores',
        icon: 'feather-users',
        link: '/admin/employees',
    },
    {
        title: 'Engage/Comunidades',
        icon: 'feather-volume-2',
        link: '/admin/groups',
    },
    {
        title: 'Notícias',
        icon: 'feather-tv',
        link: '/admin/news',
    },
    {
        title: 'Eventos',
        icon: 'feather-calendar',
        link: '/admin/events',
    },
    {
        title: 'Bem-estar',
        icon: 'feather-sun',
        link: '/admin/moodtracker',
    },
    {
        title: 'Aplausos',
        icon: 'feather-award',
        link: '/admin/badges',
    },
    {
        title: 'Benefícios e Parcerias',
        icon: 'feather-gift',
        link: '/admin/benefits',
    },
    {
        title: 'Market',
        icon: 'feather-shopping-bag',
        link: '/admin/market/orders',
    },
    {
        title: 'Learning',
        icon: 'feather-book',
        link: '/admin/learning',
    },
    {
        title: 'Formação',
        icon: 'feather-briefcase',
        link: '/admin/training/trainingActions',
    },
    {
        title: 'Bolsas de Estudo',
        icon: 'feather-book-open',
        link: '/admin/scholarships/funds',
    },
    {
        title: 'Canal de Denúncias',
        icon: 'feather-volume-1',
        link: '/admin/whistleblowing/complaints',
    },
    {
        title: 'Recrutamento',
        icon: 'feather-user-plus',
        link: '/admin/recruitment',
    },
    {
        title: 'Refer a Friend',
        icon: 'feather-users',
        link: '/admin/referrals',
    },
    {
        title: 'Links Rápidos',
        icon: 'feather-link',
        link: '/admin/quicklinks',
    },
    {
        title: 'Ideias e Sugestões',
        icon: 'feather-archive',
        link: '/admin/suggestions',
    },
    {
        title: 'Documentos',
        icon: 'feather-folder',
        link: '/admin/documents',
    },
    {
        title: 'Turnos',
        icon: 'feather-clock',
        link: '/admin/shifts',
    },
    {
        title: 'Páginas',
        icon: 'feather-layout',
        link: '/admin/staticPages',
    },
    {
        title: 'FAQs',
        icon: 'feather-help-circle',
        link: '/admin/faqs',
    },
    {
        title: 'Configurações',
        icon: 'feather-settings',
        link: '/admin/settings',
    },
    
]

function AdminHome() {
    
    const navigate = useNavigate();

    const { loading, error, data, refetch } = useQuery(GET_COUNTERS_QUERY);

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf fallback={<Forbidden />}>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content bg-white">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="Administração"/>
                                </div>

                                { false &&
                                <div className="col-lg-12">
                                    <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <img src="https://via.placeholder.com/420x270.png" alt="banner" className="w-100" />
                                            </div>
                                            <div className="col-lg-6 ps-lg-5">
                                                <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">{ config.APP_NAME }</h2>
                                                <p className="font-xssss fw-500 text-grey-500 lh-26">After completing this course you'll be confident to create any subtle to complex animation that will turn any project a professional work.</p>
                                                <a href="/defaultanalytics" className="btn w200 border-0 bg-primary-gradiant p-3 text-white fw-600 rounded-3 d-inline-block font-xssss">Analysis</a>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                                }
                                <div className="col-lg-3 pe-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e2f6e9`}}>
                                        <div className="card-body d-flex p-0">
                                            <i className="btn-round-lg d-inline-block me-3 bg-success feather-users font-md text-white"></i>
                                            <h4 className="text-success font-xl fw-700">{ data?.EmployeeCount } <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Colaboradores</span></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 pe-2 ps-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#fff0e9`}}>
                                        <div className="card-body d-flex p-0">
                                            <i className="btn-round-lg d-inline-block me-3 bg-warning feather-edit font-md text-white"></i>
                                            <h4 className="text-warning font-xl fw-700">{ data?.PostCount } <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Publicações</span></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 pe-2 ps-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#f6f3ff`}}>
                                        <div className="card-body d-flex p-0">
                                            <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-message-circle font-md text-white"></i>
                                            <h4 className="text-secondary font-xl fw-700">{ data?.Comment_Count } <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Comentários</span></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 ps-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e5f6ff`}}>
                                        <div className="card-body d-flex p-0">
                                            <i className="btn-round-lg d-inline-block me-3 bg-primary feather-shopping-bag font-md text-white"></i>
                                            <h4 className="text-primary font-xl fw-700">{ data?.Order_Count } <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Encomendas</span></h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 mb-3">
                                    <div className="card w-100 p-3 border-0 mb-3 rounded-xxl shadow-none overflow-hidden">
                                        <div className="row">
                                        {featureList.map((value , index) => (
                                            <div className="col-md-3 pointer" onClick={() => navigate(value.link)}>
                                                <div key={index} className="card d-block w-100 border-0 mb-3 shadow-xss bg-white rounded-3 p-4">
                                                    <div className="w-100 text-center mt-3 mb-3"><i className={`btn-round-lg d-inline-block bg-primary-gradiant ${value.icon} font-md text-white`}></i></div>
                                                    
                                                    <h4 className="font-xss fw-700 text-grey-500 mb-3 text-center">{value.title}</h4>
                                                </div>
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminHome;
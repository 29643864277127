import React, { Component , Fragment, useState, useEffect } from "react";

import { useQuery, gql, useMutation } from '@apollo/client';

import { InView } from "react-intersection-observer";

import moment from "moment";

import { RBACWrapper } from "react-simple-rbac";

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';


import Friends from '../components/Friends';
import Contacts from '../components/Contacts';
import Group from '../components/Group';
import Events from '../components/Events';
import Createpost from '../components/Createpost';
import Memberslider from '../components/Memberslider';
import Friendsilder from '../components/Friendsilder';
import Storyslider from '../components/Storyslider';
import Postview from '../components/Postview';
import Load from '../components/Load';
import Profilephoto from '../components/Profilephoto';
import MoodTracker from "../components/MoodTracker";
import Birthdays from "../components/Birthdays";
import FeedLinkShortcuts from '../components/FeedLinkShortcuts';
import FeedWelcome from "../components/FeedWelcome";
import FeedbackRequests from "../components/FeedbackRequests";
import LearningItemsWidget from "../components/LearningItemsWidget";
import EventsWidget from "../components/EventsWidget";
import NewsSlider from "../components/NewsSlider";

import { InputGroup } from "react-bootstrap";
import { getImageUrl, getProfilePicture } from "../utils";
import { ENABLED_FEATURES, config } from "../config";
import Forbidden from "./Forbidden";
import JobOpportunityWidget from "../components/JobOpportunityWidget";
import MyTeamWidget from "../components/MyTeamWidget";
import PostReactions from "../components/PostReactions";
import track, { useTracking } from "react-tracking";
import RecentDocumentsWidget from "../components/RecentDocumentsWidget";
import CompanyWidget from "../components/CompanyWidget";
import MobileAppCallToActionWidget from "../components/MobileAppCallToActionWidget";


const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            name
            employee {
                id
                jobTitle
                company {
                    id
                    name
                }
            }
        }
    }
`;

const GET_POSTS_QUERY = gql`
    query GetPosts($limit: Int, $offset: Int) {
        PostList(filter: {AND: [{group: { _isNull: true } }, {isPinned: { _eq: false } }]}, orderBy: {createdDate: DESC}, limit: $limit, offset: $offset) {
            id
            title
            content
            type
            isPinned
            allowComments
            user {
                id
                name
                picture
                employee {
                    id
                    name
                    jobTitle
                    picture
                    birthdate
                    user {
                        id
                        picture
                    }
                }
            }
            thumbnail
            group {
                id
                name
            }
            employee {
                id
                name
                jobTitle
                picture
                birthdate
            }
            event {
                id
                title
                startDate
                endDate
                location
                thumbnail
                createdDate
            }
            benefit {
                id
                name
                thumbnail
                category {
                    id
                    name
                }
            }
            newsArticle {
                id
                title
                headline
                content
                thumbnail
                category {
                    id
                    name
                }
            }
            mediaGallery {
                id
            }
            survey {
                id
            }
            createdDate
            comments {
                id
                content
                user {
                    id
                    name
                    picture
                    employee {
                        id
                        name
                        jobTitle
                        picture
                        birthdate
                        user {
                            id
                            picture
                        }
                    }
                }
                createdDate
                replies {
                    id
                    content
                    user {
                        id
                        name
                        picture
                        employee {
                            id
                            name
                            jobTitle
                            picture
                            birthdate
                            user {
                                id
                                picture
                            }
                        }
                    }
                    createdDate
                }
            }
            reactions {
                id
                type
                user {
                    id
                }
            }
            attachments {
                id
                name
                file
            }
        }
    }
`;

const GET_PINNED_POSTS_QUERY = gql`
    query GetPinnedPosts {
        PostList(filter: {AND: [{group: { _isNull: true } }, {isPinned: { _eq: true } }]}, orderBy: {createdDate: DESC}) {
            id
            title
            content
            type
            isPinned
            allowComments
            user {
                id
                name
                picture
                employee {
                    id
                    name
                    jobTitle
                    picture
                    user {
                        id
                        picture
                    }
                }
            }
            thumbnail
            group {
                id
                name
            }
            employee {
                id
                name
                jobTitle
                picture
                birthdate
                user {
                    id
                    picture
                }
            }
            event {
                id
                title
                startDate
                endDate
                location
                thumbnail
                createdDate
            }
            benefit {
                id
                name
                thumbnail
                category {
                    id
                    name
                }
            }
            newsArticle {
                id
                title
                headline
                content
                thumbnail
                category {
                    id
                    name
                }
            }
            mediaGallery {
                id
            }
            survey {
                id
            }
            createdDate
            comments {
                id
                content
                user {
                    id
                    name
                    picture
                    employee {
                        id
                        name
                        picture
                        birthdate
                        jobTitle
                        user {
                            id
                            picture
                        }
                    }
                }
                createdDate
                replies {
                    id
                    content
                    user {
                        id
                        name
                        picture
                        employee {
                            id
                            name
                            picture
                            birthdate
                            jobTitle
                        }
                    }
                    createdDate
                }
            }
            reactions {
                id
                type
                user {
                    id
                }
            }
            attachments {
                id
                name
                file
            }
        }
    }
`;

const GET_WIDGETS_ITEMS_QUERY = gql`
    query GetWidgetsItems {
        QuickLinkList(filter: { isVisible: { _eq: true } }, orderBy: { displayOrder: ASC, createdDate: ASC }) {
            id
            title
            icon
            url
            displayOrder
            openInNewWindow
        }
        LearningItemList(filter: { isVisible: { _eq: true } }, orderBy: { createdDate: DESC }, limit: 4) {
            id
            title
            thumbnail
            thumbnailExternalUrl
            category {
                id
                name
            }
        }
        JobOpportunityList(filter: { AND: [ { isActive: { _eq: true } }, { type: { _eq: INTERNAL } } ] }, orderBy: { createdDate: DESC }, limit: 4) {
            id
            title
            location
            createdDate
        }
    }
`;

const UPSERT_POST_REACTION_MUTATION = gql`
    mutation UpsertPostReaction($id: UUID, $type: ReactionType, $userId: UUID) {
        upsert_PostReaction(postReaction:{ post: { id: $id }, type: $type, user: { id: $userId } }) {
            id
        }
    }
`;

const DELETE_POST_REACTION_MUTATION = gql`
    mutation DeletePostReaction($id: String!) {
        delete_PostReaction(id: $id)
    }
`;

const UPSERT_POST_COMMENT_MUTATION = gql`
    mutation UpsertPostComment($id: UUID, $content: String, $userId: UUID) {
        upsert_PostComment(postComment: {
            post: { id: $id },
            user: { id: $userId },
            content: $content
        }) {
            id
        }
    }
`;

const UPSERT_COMMENT_REPLY_MUTATION = gql`
    mutation UpsertCommentReply($id: UUID, $content: String, $userId: UUID) {
        upsert_CommentReply(commentReply: {
            parentComment: { id: $id },
            user: { id: $userId },
            content: $content
        }) {
            id
        }
    }
`;

function Feed() {
    
    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { data: widgetsItemsData, loading: widgetsItemsLoading, error: widgetsItemsError } = useQuery(GET_WIDGETS_ITEMS_QUERY);
    const { data: pinnedPostsData, loading: pinnedPostsLoading, error: pinnedPostsError, refetch: pinnedPostsRefetch } = useQuery(GET_PINNED_POSTS_QUERY);
    const { data: postsData, loading: postsLoading, fetchMore: postsFetchMore, error: postsError, refetch: postsRefetch } = useQuery(GET_POSTS_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
        variables: { limit: 10, offset: 0 }
    });

    const [upsertPostReactionMutation, { upsertPostReactionMutationData }] = useMutation(UPSERT_POST_REACTION_MUTATION);
    const [deletePostReactionMutation, { deletePostReactionMutationData }] = useMutation(DELETE_POST_REACTION_MUTATION);

    const [upsertPostCommentMutation, { upsertPostCommentMutationData }] = useMutation(UPSERT_POST_COMMENT_MUTATION);
    const [upsertCommentReplyMutation, { upsertCommentReplyMutationData }] = useMutation(UPSERT_COMMENT_REPLY_MUTATION);

    //if (postsLoading && !postsData) return <LoadingOverlayWrapper/>;
    //if(viewerLoading) return <LoadingOverlayWrapper />;
    
    const handleLike = async (id) => {
        await upsertPostReactionMutation({ 
            variables: {
                id,
                type: 'LIKE',
                userId: viewerData.Viewer.id
            },
        })
        await pinnedPostsRefetch();
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }

    const handleLove = async (id) => {
        await upsertPostReactionMutation({ 
            variables: {
                id,
                type: 'LOVE',
                userId: viewerData.Viewer.id
            },
        })
        await pinnedPostsRefetch();
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }

    const handleDeleteReaction = async (reactionId) => {
        await deletePostReactionMutation({ 
            variables: {
                id: reactionId
            },
        })
        await pinnedPostsRefetch();
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }

    const handleCreateComment = async (id, content) => {
        await upsertPostCommentMutation({ 
            variables: {
                id,
                content,
                userId: viewerData.Viewer.id
            },
        })
        await pinnedPostsRefetch();
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }

    const handleCreateCommentReply = async (id, content) => {
        await upsertCommentReplyMutation({ 
            variables: {
                id,
                content,
                userId: viewerData.Viewer.id
            },
        })
        await pinnedPostsRefetch();
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }

    const handlePostUpdated = async () => {
        await pinnedPostsRefetch();
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }

    return (
        <RBACWrapper requiredRoles={['user', 'admin', 'post-contributor']} oneOf fallback={<Forbidden />}>
            <Fragment> 
                <Header />
                <Leftnav />
                <MoodTracker/>

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row feed-body">
                                <div className="col-xl-8 col-xxl-9 col-lg-8">
                                    { false && <Storyslider /> }
                                    <FeedLinkShortcuts links={widgetsItemsData && widgetsItemsData.QuickLinkList} />
                                    { viewerData && <FeedWelcome user={viewerData?.Viewer} /> }
                                    <MobileAppCallToActionWidget user={viewerData?.Viewer} />
                                    <MyTeamWidget  employeeId={viewerData?.Viewer?.employee?.id} />
                                    <RBACWrapper requiredRoles={['admin', 'post-contributor']} oneOf>
                                        <Createpost user={viewerData?.Viewer} onPostCreated={handlePostUpdated} />
                                    </RBACWrapper>
                                    {false && <PostReactions id="cd68501e-66ce-eda7-d85d-31815cb44de7" />}
                                    {pinnedPostsData && pinnedPostsData.PostList && pinnedPostsData.PostList.map((value , index) => (
                                        <Postview key={value.id} id={value.id} type={value.type} postvideo="" title={value.title} postimage={getImageUrl(value.thumbnail)} avater={getProfilePicture(value.user)} user={value.user} time={value.createdDate} des={value.content} pin={value.isPinned} allowComments={value.allowComments} comments={value.comments} attachments={value.attachments} handleCreateComment={handleCreateComment} handleCreateReply={handleCreateCommentReply} reactions={value.reactions} handleLike={() => handleLike(value.id)} handleLove={() => handleLove(value.id)} handleDeleteReaction={handleDeleteReaction} currentUser={viewerData && viewerData.Viewer.id} employee={value.employee} event={value.event} benefit={value.benefit} newsArticle={value.newsArticle} onPostUpdated={handlePostUpdated} galleryId={value?.mediaGallery?.id} surveyId={value?.survey?.id} group={value?.group} />
                                    ))}
                                    <NewsSlider />
                                    {postsData && postsData.PostList && postsData.PostList.map((value , index) => (
                                        <Postview key={value.id} id={value.id} type={value.type} postvideo="" title={value.title} postimage={getImageUrl(value.thumbnail)} avater={getProfilePicture(value.user)} user={value.user} time={value.createdDate} des={value.content} pin={value.isPinned} allowComments={value.allowComments} comments={value.comments} attachments={value.attachments} handleCreateComment={handleCreateComment} handleCreateReply={handleCreateCommentReply} reactions={value.reactions} handleLike={() => handleLike(value.id)} handleLove={() => handleLove(value.id)} handleDeleteReaction={handleDeleteReaction} currentUser={viewerData && viewerData.Viewer.id} employee={value.employee} event={value.event} benefit={value.benefit} newsArticle={value.newsArticle} onPostUpdated={handlePostUpdated} galleryId={value?.mediaGallery?.id} surveyId={value?.survey?.id} group={value?.group} />
                                    ))}
                                    {false && <div>
                                    <Postview id="32" postvideo="" postimage="post.png" avater="user.png" user="Surfiya Zakir" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                    <Postview id="31" postvideo="" postimage="post.png" avater="user.png" user="David Goria" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                    <Postview id="33" postvideo="" postimage="post.png" avater="user.png" user="Anthony Daugloi" time="2 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                    <Memberslider />
                                    <Postview id="35" postvideo="" postimage="post.png" avater="user.png" user="Victor Exrixon" time="3 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                    <Friendsilder />
                                    <Postview id="36" postvideo="" postimage="post.png" avater="user.png" user="Victor Exrixon" time="12 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                    </div>}
                                    { postsLoading && <Load /> }
                                    {postsData && (
                                        <InView
                                        onChange={async (inView) => {
                                            const currentLength = postsData.PostList.length || 0;
                                            if (inView) {
                                                console.log('postsFetchMore')
                                                await postsFetchMore({
                                                    variables: {
                                                        offset: currentLength,
                                                        limit: 10,
                                                    },
                                                });
                                            }
                                        }}
                                        />
                                    )}
                                </div>
                                <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                                    <CompanyWidget />
                                    <Birthdays />
                                    { ENABLED_FEATURES.feedback && viewerData && viewerData.Viewer && viewerData.Viewer.employee && <FeedbackRequests employeeId={viewerData.Viewer.employee.id} /> }
                                    <LearningItemsWidget title={<>O que vais aprender hoje? <i className="em em-clap ms-1"></i></>} items={widgetsItemsData && widgetsItemsData.LearningItemList} />
                                    <RecentDocumentsWidget />
                                    <JobOpportunityWidget title={<>Vagas Internas <i className="em em-briefcase ms-1"></i></>} items={widgetsItemsData && widgetsItemsData.JobOpportunityList} />
                                    <EventsWidget />
                                    { config.FEATURES_PREVIEW_ENABLED && <Friends /> }
                                    { config.FEATURES_PREVIEW_ENABLED && <Contacts /> }
                                    { config.FEATURES_PREVIEW_ENABLED && <Group /> }
                                    { config.FEATURES_PREVIEW_ENABLED && <Events /> }
                                    { config.FEATURES_PREVIEW_ENABLED && <Profilephoto />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

//export default Feed;

export default track(
    { page: "Feed" },
    { dispatchOnMount: contextData => ({ event: "pageLoaded" }) }
)(Feed);
import React, { Fragment } from "react";

import { useQuery, gql, useMutation } from '@apollo/client';

import moment from "moment";

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';


import Postview from '../components/Postview';
import Load from '../components/Load';
import { getImageUrl, getProfilePicture } from "../utils";
import { useParams } from "react-router";
import track from "react-tracking";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
        }
    }
`;

const GET_POST_QUERY = gql`
    query GetPost($id: String!) {
        PostById(id: $id) {
            id
            title
            content
            type
            isPinned
            allowComments
            user {
                id
                name
                picture
                employee {
                    id
                    name
                    jobTitle
                    birthdate
                    picture
                    user {
                        id
                        picture
                    }
                }
            }
            thumbnail
            group {
                id
                name
            }
            employee {
                id
                name
                birthdate
                jobTitle
            }
            event {
                id
                title
                startDate
                endDate
                location
                thumbnail
                createdDate
            }
            benefit {
                id
                name
                thumbnail
                category {
                    id
                    name
                }
            }
            newsArticle {
                id
                title
                headline
                content
                thumbnail
                category {
                    id
                    name
                }
            }
            mediaGallery {
                id
            }
            survey {
                id
            }
            createdDate
            comments {
                id
                content
                user {
                    id
                    name
                    picture
                    employee {
                        id
                        name
                        jobTitle
                        birthdate
                    }
                }
                createdDate
                replies {
                    id
                    content
                    user {
                        id
                        name
                        picture
                        employee {
                            id
                            name
                            jobTitle
                            birthdate
                        }
                    }
                    createdDate
                }
            }
            reactions {
                id
                type
                user {
                    id
                }
            }
            attachments {
                id
                name
                file
            }
        }
    }
`;

const UPSERT_POST_REACTION_MUTATION = gql`
    mutation UpsertPostReaction($id: UUID, $type: ReactionType, $userId: UUID) {
        upsert_PostReaction(postReaction:{ post: { id: $id }, type: $type, user: { id: $userId } }) {
            id
        }
    }
`;

const DELETE_POST_REACTION_MUTATION = gql`
    mutation DeletePostReaction($id: String!) {
        delete_PostReaction(id: $id)
    }
`;

const UPSERT_POST_COMMENT_MUTATION = gql`
    mutation UpsertPostComment($id: UUID, $content: String, $userId: UUID) {
        upsert_PostComment(postComment: {
            post: { id: $id },
            user: { id: $userId },
            content: $content
        }) {
            id
        }
    }
`;

const UPSERT_COMMENT_REPLY_MUTATION = gql`
    mutation UpsertCommentReply($id: UUID, $content: String, $userId: UUID) {
        upsert_CommentReply(commentReply: {
            parentComment: { id: $id },
            user: { id: $userId },
            content: $content
        }) {
            id
        }
    }
`;

function PostSingle() {

    const { id } = useParams();

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { data: postData, loading: postLoading, error: postError, refetch: postRefetch } = useQuery(GET_POST_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
        variables: { id: id }
    });

    const [upsertPostReactionMutation, { upsertPostReactionMutationData }] = useMutation(UPSERT_POST_REACTION_MUTATION);
    const [deletePostReactionMutation, { deletePostReactionMutationData }] = useMutation(DELETE_POST_REACTION_MUTATION);

    const [upsertPostCommentMutation, { upsertPostCommentMutationData }] = useMutation(UPSERT_POST_COMMENT_MUTATION);
    const [upsertCommentReplyMutation, { upsertCommentReplyMutationData }] = useMutation(UPSERT_COMMENT_REPLY_MUTATION);

    //if (postsLoading && !postsData) return <LoadingOverlayWrapper/>;
    //if(viewerLoading) return <LoadingOverlayWrapper />;
    
    const handleLike = async (id) => {
        await upsertPostReactionMutation({ 
            variables: {
                id,
                type: 'LIKE',
                userId: viewerData.Viewer.id
            },
        })
        await postRefetch();
    }

    const handleLove = async (id) => {
        await upsertPostReactionMutation({ 
            variables: {
                id,
                type: 'LOVE',
                userId: viewerData.Viewer.id
            },
        })
        await postRefetch();
    }

    const handleDeleteReaction = async (reactionId) => {
        await deletePostReactionMutation({ 
            variables: {
                id: reactionId
            },
        })
        await postRefetch();
    }

    const handleCreateComment = async (id, content) => {
        await upsertPostCommentMutation({ 
            variables: {
                id,
                content,
                userId: viewerData.Viewer.id
            },
        })
        await postRefetch();
    }

    const handleCreateCommentReply = async (id, content) => {
        await upsertCommentReplyMutation({ 
            variables: {
                id,
                content,
                userId: viewerData.Viewer.id
            },
        })
        await postRefetch();
    }

    const handlePostUpdated = async () => {
        await postRefetch();
    }

    const post = postData && postData.PostById

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                { post && <Postview key={post.id} id={post.id} type={post.type} postvideo="" title={post.title} postimage={getImageUrl(post.thumbnail)} avater={getProfilePicture(post.user)} user={post.user} time={post.createdDate} des={post.content} pin={post.isPinned} allowComments={post.allowComments} comments={post.comments} attachments={post.attachments} handleCreateComment={handleCreateComment} handleCreateReply={handleCreateCommentReply} reactions={post.reactions} handleLike={() => handleLike(post.id)} handleLove={() => handleLove(post.id)} handleDeleteReaction={handleDeleteReaction} currentUser={viewerData && viewerData.Viewer.id} employee={post.employee} event={post.event} benefit={post.benefit} newsArticle={post.newsArticle} onPostUpdated={handlePostUpdated} galleryId={post?.mediaGallery?.id} surveyId={post?.survey?.id} group={post?.group} /> }

                                { postLoading && <Load /> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Appfooter /> 
        </Fragment>
    );
}

//export default PostSingle;

export default track(
    { page: "PostSingle" },
    //{ dispatchOnMount: contextData => ({ event: "pageLoaded" }) }
)(PostSingle);
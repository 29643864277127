import React, { Fragment, useRef, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm, Controller } from "react-hook-form";

import DatePicker from "react-multi-date-picker";

import { RBACWrapper } from "react-simple-rbac";
import Moment from "react-moment";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from "../../../components/Appfooter";
import Pagetitle from "../../../components/Pagetitle";
import { getImageUrl, getProfilePicture } from "../../../utils";
import { GRAPHQL_MAX_RESULTS_LIMIT, PERSON_GENDER_OPTIONS } from "../../../config";
import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";


const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            employee {
                id
            }
        }
    }
`;

const UPSERT_MUTATION = gql`
    mutation Upsert($requestId: UUID!) {
        ProcessEmployeeImportRequest(requestId: $requestId ) {
            employee {
                id
                externalId
                fullName
                name
                birthdate
                gender
                jobTitle
                company {
                    id
                    name
                }
                department {
                    id
                    name
                }
                manager {
                    id
                    fullName
                }
                hireDate
                terminationDate
                email
                upn
                phone
                mobile
                isExternal
            }
            errors {
                field
                errorCode
            }
        }
    }
`;

const UPSERT_EMPLOYEE_IMPORT_REQUEST_MUTATION = gql`
    mutation Upsert($user: inp_User!, $file: Upload!) {
        upsert_EmployeeImportRequest(employeeImportRequest: { user: $user, file: $file } ) {
            id
        }
    }
`;

const UPSERT_EMPLOYEES_IMPORT_MUTATION = gql`
    mutation Upsert($employees: [inp_Employee]) {
        EmployeesImport(employees: $employees ) {
            id
        }
    }
`;

function AdminImportEmployees() {

    const navigate = useNavigate();
    
    let { id } = useParams();

    const [selectedFile, setSelectedFile] = useState(null);
    const [importResult, setImportResult] = useState(null);

    const inputFile = useRef(null) 

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset, control } = useForm();

    const [upsertMutation, { upsertMutationData, loading: upsertMutationLoading }] = useMutation(UPSERT_MUTATION);
    const [upsertEmployeeImportRequestMutation, { upsertEmployeeImportRequestMutationData, loading: upsertEmployeeImportRequestMutationLoading }] = useMutation(UPSERT_EMPLOYEE_IMPORT_REQUEST_MUTATION);
    const [upsertEmployeesImportMutation, { upsertEmployeesImportMutationData, loading: upsertEmployeesImportMutationLoading }] = useMutation(UPSERT_EMPLOYEES_IMPORT_MUTATION);
    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);

    const upsert = async (formData) => {
        if(!selectedFile) {
            toast.error("Seleccione um ficheiro")
            return;
        }

        const uploadResult = await uploadFile(selectedFile);

        if(!uploadResult) {
            toast.error('Ocorreu um erro ao enviar o ficheiro!')
            return;
        }

        const upsertResult = await upsertMutation({ 
            variables: {
                requestId: uploadResult?.id,
            },
        });

        if(upsertResult.data) {
            //toast.success('Alterações guardadas!');
            //navigate(`/admin/employees/${upsertResult.data.upsert_Employee.id}`)
            setImportResult(upsertResult?.data?.ProcessEmployeeImportRequest)
        } else {
            toast.error('Ocorrer um erro ao processar o ficheiro!')
        }
    }

    const uploadFile = async (file) => {
        const upsertResult = await upsertEmployeeImportRequestMutation({ 
            variables: {
                user: { id: viewerData?.Viewer?.id },
                file: file,
            },
        });
        return upsertResult?.data?.upsert_EmployeeImportRequest
    }

    const onFileChange = async(event) => { 
        let file = event.target.files[0];
        setSelectedFile(file)
    };

    const upsertImportEmployees = async () => {
        if(!importResult) {
            toast.error("Ocorreu um erro")
            return;
        }

        if(importResult?.filter(e => e.errors.length > 0)?.length > 0) {
            toast.warn("Deve corrigir os erros antes de prosseguir a importação")
            return;
        }

        const upsertResult = await upsertEmployeesImportMutation({ 
            variables: {
                employees: importResult.map(e => e.employee)
            },
        });

        if(upsertResult.data) {
            console.log(upsertResult.data)
            toast.success(`${upsertResult.data.EmployeesImport?.count} colaboradores importados!`);
            navigate(`/admin/employees`)
            
        } else {
            toast.error('Ocorrer um erro ao importar os colaboradores!')
        }
    }

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 cart-wrapper mb-4">
                                    <Pagetitle title="Colaboradores > Importar"/>

                                    { !importResult && !upsertEmployeeImportRequestMutationLoading && !upsertMutationLoading &&
                                    <form onSubmit={handleSubmit(upsert)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <a href="#">Download Template</a>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="card mt-3 border-0">
                                                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                        <div className="form-group mb-0 w-100">
                                                            <label className="mont-font fw-600 font-xsss">Ficheiro (*.xlsx)</label>
                                                            <input type="file" name="file" id="file" className="input-file" onChange={onFileChange} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                                            <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                            <i className={`${selectedFile ? 'ti-file' : 'ti-cloud-down'} large-icon me-3 d-block`}></i>
                                                            <span className="js-fileName">{ selectedFile ? selectedFile.name : 'Clique para selecionar um ficheiro' }</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <div className="float-right">
                                                    <a role="button" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={handleSubmit(upsert)}>Continuar</a>
                                                </div>
                                            </div>
                                        </div>
                                    </form> }

                                    <CustomLoadingOverlay active={upsertEmployeeImportRequestMutationLoading || upsertMutationLoading} />

                                    { importResult && 
                                    <Fragment>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ importResult?.length } colaboradores <span className="text-warning">{ importResult?.filter(e => e.errors.length > 0)?.length > 0 && `${importResult?.filter(e => e.errors.length > 0)?.length} erros` }</span></h4>
                                            { false && <div className="float-right">,
                                                <Link to="/admin/insights/employees" className="p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl"><i className={`feather-trending-up`}></i> Insights</Link>
                                                <Link to="/admin/users/signupRequests" className="p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Ver Pedidos de Registo</Link>
                                                <Link to="/admin/employees/new" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Novo Colaborador</Link>
                                                <Link to="/admin/employees/import" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Importar</Link>
                                            </div> }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">

                                            <div className="table-content table-responsive scroll-bar scroll-bar-x">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                            <th className="border-0 p-4">ID Externo</th>
                                                            <th className="border-0 p-4 text-left">Nome Completo</th>
                                                            <th className="border-0 p-4 text-left">Nome</th>
                                                            <th className="border-0 p-4">Data de Nascimento</th>
                                                            <th className="border-0 p-4">Género</th>
                                                            <th className="border-0 p-4">Email</th>
                                                            <th className="border-0 p-4">Telefone</th>
                                                            <th className="border-0 p-4">Telemóvel</th>
                                                            <th className="border-0 p-4">Empresa</th>
                                                            <th className="border-0 p-4">Departmento</th>
                                                            <th className="border-0 p-4">Função</th>
                                                            <th className="border-0 p-4">Superior Hierárquico</th>
                                                            <th className="border-0 p-4">Data de Admissão</th>
                                                            <th className="border-0 p-4">Data de Rescisão</th>
                                                            <th className="border-0 p-4">Externo?</th>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {importResult?.map((value, index) => (
                                                        <tr key={value.employee.id + '-' + value.employee.fullName}>
                                                            <td className="text-center ps-0">
                                                                { value.employee.id ? (
                                                                    <span className={`p-1 ps-2 pe-2 text-warning alert-warning font-xssss rounded-3`}><strong>Existente</strong></span>
                                                                ) : (
                                                                    <span className={`p-1 ps-2 pe-2 text-success alert-success font-xssss rounded-3`}><strong>Novo</strong></span>
                                                                )}
                                                            </td>
                                                            <td className="text-center">
                                                                { value.errors?.filter(e => e.field === 'externalId')[0] && <i className="font-md text-warning feather-alert-octagon"></i> }
                                                                <span className="text-grey-500 font-xsss">{value.employee.externalId}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                { value.errors?.filter(e => e.field === 'fullName')[0] && <i className="font-md text-warning feather-alert-octagon"></i> }
                                                                <span className="text-grey-500 font-xsss">{value.employee.fullName}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                { value.errors?.filter(e => e.field === 'name')[0] && <i className="font-md text-warning feather-alert-octagon"></i> }
                                                                <span className="text-grey-500 font-xsss">{value.employee.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                { value.errors?.filter(e => e.field === 'birthdate')[0] && <i className="font-md text-warning feather-alert-octagon"></i> }
                                                                <span className="text-grey-900 font-xsss">{value.employee.birthdate ? <Moment format="DD/MM/YYYY">{value.employee.birthdate}</Moment> : '-'}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                { value.errors?.filter(e => e.field === 'gender')[0] && <i className="font-md text-warning feather-alert-octagon"></i> }
                                                                <span className="text-grey-900 font-xsss">{PERSON_GENDER_OPTIONS.filter(e => e.id === value.employee.gender)[0]?.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                { value.errors?.filter(e => e.field === 'email')[0] && <i className="font-md text-warning feather-alert-octagon"></i> }
                                                                <span className="text-grey-500 font-xsss">{value.employee.email}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                { value.errors?.filter(e => e.field === 'phone')[0] && <i className="font-md text-warning feather-alert-octagon"></i> }
                                                                <span className="text-grey-500 font-xsss">{value.employee.phone}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                { value.errors?.filter(e => e.field === 'mobile')[0] && <i className="font-md text-warning feather-alert-octagon"></i> }
                                                                <span className="text-grey-500 font-xsss">{value.employee.mobile}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                { value.errors?.filter(e => e.field === 'company')[0] && <i className="font-md text-warning feather-alert-octagon"></i> }
                                                                <span className="text-grey-900 font-xsss">{value.employee.company?.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                { value.errors?.filter(e => e.field === 'department')[0] && <i className="font-md text-warning feather-alert-octagon"></i> }
                                                                <span className="text-grey-900 font-xsss">{value.employee.department?.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                { value.errors?.filter(e => e.field === 'jobTitle')[0] && <i className="font-md text-warning feather-alert-octagon"></i> }
                                                                <span className="text-grey-900 font-xsss">{value.employee.jobTitle}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                { value.errors?.filter(e => e.field === 'externalId')[0] && <i className="font-md text-warning feather-alert-octagon"></i> }
                                                                <span className="text-grey-900 font-xsss">{value.employee.manager?.fullName}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                { value.errors?.filter(e => e.field === 'hireDate')[0] && <i className="font-md text-warning feather-alert-octagon"></i> }
                                                                <span className="text-grey-900 font-xsss">{value.employee.hireDate ? <Moment format="DD/MM/YYYY">{value.employee.hireDate}</Moment> : '-'}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                { value.errors?.filter(e => e.field === 'terminationDate')[0] && <i className="font-md text-warning feather-alert-octagon"></i> }
                                                                <span className="text-grey-900 font-xsss">{value.employee.terminationDate ? <Moment format="DD/MM/YYYY">{value.employee.terminationDate}</Moment> : '-'}</span>
                                                            </td>
                                                            <td className="text-center">{ value.employee.isExternal && <span className={`p-1 ps-2 pe-2 text-warning alert-warning font-xssss rounded-3`}><strong>Externo</strong></span> }</td>
                                                            
                                                            <td className="text-right">
                                                            { value.errors?.length > 0 && <i className="font-md text-danger feather-alert-triangle"></i> }
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mb-0">
                                            <div className="float-right">
                                                <a role="button" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={upsertImportEmployees}>Importar</a>
                                            </div>
                                        </div>
                                    </div>
                                    </Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminImportEmployees;
import React, { Component , Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from '@apollo/client';

import { InView } from "react-intersection-observer";

import moment from "moment";

import { RBACWrapper } from "react-simple-rbac";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';


import Createpost from '../../components/Createpost';
import Postview from '../../components/Postview';
import Load from '../../components/Load';

import { getImageUrl, getProfilePicture } from "../../utils";
import Forbidden from "../Forbidden";
import track, { useTracking } from "react-tracking";
import ProfilecardOne from "../../components/ProfilecardOne";


const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            name
            employee {
                id
                jobTitle
                company {
                    id
                    name
                }
            }
        }
    }
`;

const GET_GROUP_QUERY = gql`
    query Get($id: String!, $uuid: UUID!) {
        Group_ById(id: $id) {
            id
            name
            description
            thumbnail
            coverImage
            allowUserPosts
        }

        PostCount(filter:{ group: { id: { _eq: $uuid } } })
    }
`;

const GET_POSTS_QUERY = gql`
    query GetPosts($id: UUID!, $limit: Int, $offset: Int) {
        PostList(filter: {AND: [{group: { id: { _eq: $id } } }, {isPinned: { _eq: false } }]}, orderBy: {createdDate: DESC}, limit: $limit, offset: $offset) {
            id
            title
            content
            type
            isPinned
            allowComments
            user {
                id
                name
                picture
                employee {
                    id
                    name
                    jobTitle
                    picture
                    birthdate
                    user {
                        id
                        picture
                    }
                }
            }
            thumbnail
            group {
                id
                name
            }
            employee {
                id
                name
                jobTitle
                picture
                birthdate
            }
            event {
                id
                title
                startDate
                endDate
                location
                thumbnail
                createdDate
            }
            benefit {
                id
                name
                thumbnail
                category {
                    id
                    name
                }
            }
            newsArticle {
                id
                title
                headline
                content
                thumbnail
                category {
                    id
                    name
                }
            }
            mediaGallery {
                id
            }
            survey {
                id
            }
            createdDate
            comments {
                id
                content
                user {
                    id
                    name
                    picture
                    employee {
                        id
                        name
                        jobTitle
                        picture
                        birthdate
                        user {
                            id
                            picture
                        }
                    }
                }
                createdDate
                replies {
                    id
                    content
                    user {
                        id
                        name
                        picture
                        employee {
                            id
                            name
                            jobTitle
                            picture
                            birthdate
                            user {
                                id
                                picture
                            }
                        }
                    }
                    createdDate
                }
            }
            reactions {
                id
                type
                user {
                    id
                }
            }
            attachments {
                id
                name
                file
            }
        }
    }
`;

const GET_PINNED_POSTS_QUERY = gql`
    query GetPinnedPosts($id: UUID!) {
        PostList(filter: {AND: [{group: { id: { _eq: $id } } }, {isPinned: { _eq: true } }]}, orderBy: {createdDate: DESC}) {
            id
            title
            content
            type
            isPinned
            allowComments
            user {
                id
                name
                picture
                employee {
                    id
                    name
                    jobTitle
                    picture
                    user {
                        id
                        picture
                    }
                }
            }
            thumbnail
            group {
                id
                name
            }
            employee {
                id
                name
                jobTitle
                picture
                birthdate
                user {
                    id
                    picture
                }
            }
            event {
                id
                title
                startDate
                endDate
                location
                thumbnail
                createdDate
            }
            benefit {
                id
                name
                thumbnail
                category {
                    id
                    name
                }
            }
            newsArticle {
                id
                title
                headline
                content
                thumbnail
                category {
                    id
                    name
                }
            }
            mediaGallery {
                id
            }
            survey {
                id
            }
            createdDate
            comments {
                id
                content
                user {
                    id
                    name
                    picture
                    employee {
                        id
                        name
                        picture
                        birthdate
                        jobTitle
                        user {
                            id
                            picture
                        }
                    }
                }
                createdDate
                replies {
                    id
                    content
                    user {
                        id
                        name
                        picture
                        employee {
                            id
                            name
                            picture
                            birthdate
                            jobTitle
                        }
                    }
                    createdDate
                }
            }
            reactions {
                id
                type
                user {
                    id
                }
            }
            attachments {
                id
                name
                file
            }
        }
    }
`;

const UPSERT_POST_REACTION_MUTATION = gql`
    mutation UpsertPostReaction($id: UUID, $type: ReactionType, $userId: UUID) {
        upsert_PostReaction(postReaction:{ post: { id: $id }, type: $type, user: { id: $userId } }) {
            id
        }
    }
`;

const DELETE_POST_REACTION_MUTATION = gql`
    mutation DeletePostReaction($id: String!) {
        delete_PostReaction(id: $id)
    }
`;

const UPSERT_POST_COMMENT_MUTATION = gql`
    mutation UpsertPostComment($id: UUID, $content: String, $userId: UUID) {
        upsert_PostComment(postComment: {
            post: { id: $id },
            user: { id: $userId },
            content: $content
        }) {
            id
        }
    }
`;

const UPSERT_COMMENT_REPLY_MUTATION = gql`
    mutation UpsertCommentReply($id: UUID, $content: String, $userId: UUID) {
        upsert_CommentReply(commentReply: {
            parentComment: { id: $id },
            user: { id: $userId },
            content: $content
        }) {
            id
        }
    }
`;

function GroupSingle() {
    
    let { id } = useParams();

    const { trackEvent } = useTracking();

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { data: groupData, loading: groupLoading, error: groupError } = useQuery(GET_GROUP_QUERY, { variables: { id: id, uuid: id }});
    const { data: pinnedPostsData, loading: pinnedPostsLoading, error: pinnedPostsError, refetch: pinnedPostsRefetch } = useQuery(GET_PINNED_POSTS_QUERY, { variables: { id: id } });
    const { data: postsData, loading: postsLoading, fetchMore: postsFetchMore, error: postsError, refetch: postsRefetch } = useQuery(GET_POSTS_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
        variables: { id: id, limit: 10, offset: 0 }
    });

    const [upsertPostReactionMutation, { upsertPostReactionMutationData }] = useMutation(UPSERT_POST_REACTION_MUTATION);
    const [deletePostReactionMutation, { deletePostReactionMutationData }] = useMutation(DELETE_POST_REACTION_MUTATION);

    const [upsertPostCommentMutation, { upsertPostCommentMutationData }] = useMutation(UPSERT_POST_COMMENT_MUTATION);
    const [upsertCommentReplyMutation, { upsertCommentReplyMutationData }] = useMutation(UPSERT_COMMENT_REPLY_MUTATION);

    useEffect(() => {
        trackEvent({ event: "view", customData: id });
    }, [])

    //if (postsLoading && !postsData) return <LoadingOverlayWrapper/>;
    //if(viewerLoading) return <LoadingOverlayWrapper />;
    
    const handleLike = async (id) => {
        await upsertPostReactionMutation({ 
            variables: {
                id,
                type: 'LIKE',
                userId: viewerData.Viewer.id
            },
        })
        await pinnedPostsRefetch();
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }

    const handleLove = async (id) => {
        await upsertPostReactionMutation({ 
            variables: {
                id,
                type: 'LOVE',
                userId: viewerData.Viewer.id
            },
        })
        await pinnedPostsRefetch();
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }

    const handleDeleteReaction = async (reactionId) => {
        await deletePostReactionMutation({ 
            variables: {
                id: reactionId
            },
        })
        await pinnedPostsRefetch();
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }

    const handleCreateComment = async (id, content) => {
        await upsertPostCommentMutation({ 
            variables: {
                id,
                content,
                userId: viewerData.Viewer.id
            },
        })
        await pinnedPostsRefetch();
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }

    const handleCreateCommentReply = async (id, content) => {
        await upsertCommentReplyMutation({ 
            variables: {
                id,
                content,
                userId: viewerData.Viewer.id
            },
        })
        await pinnedPostsRefetch();
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }

    const handlePostUpdated = async () => {
        await pinnedPostsRefetch();
        await postsRefetch({
            offset: 0,
            limit: postsData.PostList.length || 0,
        });
    }

    return (
        <RBACWrapper requiredRoles={['user', 'admin', 'post-contributor']} oneOf fallback={<Forbidden />}>
            <Fragment> 
                <Header />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row feed-body">
                                <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                                    <ProfilecardOne profile={groupData?.Group_ById} postCount={groupData?.PostCount} />
                                    { /*<Profilephoto /> */ }
                                    { /*<Profilephoto /> */ }
                                </div>
                                <div className="col-xl-8 col-xxl-9 col-lg-8 mt-3">
                                    <RBACWrapper requiredRoles={groupData?.Group_ById?.allowUserPosts ? ['admin', 'post-contributor', 'user'] : ['admin', 'post-contributor']} oneOf>
                                        <Createpost user={viewerData?.Viewer} onPostCreated={handlePostUpdated} group={groupData?.Group_ById} disableNotifications={true} />
                                    </RBACWrapper>
                                    {pinnedPostsData && pinnedPostsData.PostList && pinnedPostsData.PostList.map((value , index) => (
                                        <Postview key={value.id} id={value.id} type={value.type} postvideo="" title={value.title} postimage={getImageUrl(value.thumbnail)} avater={getProfilePicture(value.user)} user={value.user} time={value.createdDate} des={value.content} pin={value.isPinned} allowComments={value.allowComments} comments={value.comments} attachments={value.attachments} handleCreateComment={handleCreateComment} handleCreateReply={handleCreateCommentReply} reactions={value.reactions} handleLike={() => handleLike(value.id)} handleLove={() => handleLove(value.id)} handleDeleteReaction={handleDeleteReaction} currentUser={viewerData && viewerData.Viewer.id} employee={value.employee} event={value.event} benefit={value.benefit} newsArticle={value.newsArticle} onPostUpdated={handlePostUpdated} galleryId={value?.mediaGallery?.id} surveyId={value?.survey?.id} group={value?.group} />
                                    ))}
                                    {postsData && postsData.PostList && postsData.PostList.map((value , index) => (
                                        <Postview key={value.id} id={value.id} type={value.type} postvideo="" title={value.title} postimage={getImageUrl(value.thumbnail)} avater={getProfilePicture(value.user)} user={value.user} time={value.createdDate} des={value.content} pin={value.isPinned} allowComments={value.allowComments} comments={value.comments} attachments={value.attachments} handleCreateComment={handleCreateComment} handleCreateReply={handleCreateCommentReply} reactions={value.reactions} handleLike={() => handleLike(value.id)} handleLove={() => handleLove(value.id)} handleDeleteReaction={handleDeleteReaction} currentUser={viewerData && viewerData.Viewer.id} employee={value.employee} event={value.event} benefit={value.benefit} newsArticle={value.newsArticle} onPostUpdated={handlePostUpdated} galleryId={value?.mediaGallery?.id} surveyId={value?.survey?.id} group={value?.group} />
                                    ))}
                                    { postsLoading && <Load /> }
                                    {postsData && (
                                        <InView
                                        onChange={async (inView) => {
                                            const currentLength = postsData.PostList.length || 0;
                                            if (inView) {
                                                console.log('postsFetchMore')
                                                await postsFetchMore({
                                                    variables: {
                                                        id: id,
                                                        offset: currentLength,
                                                        limit: 10,
                                                    },
                                                });
                                            }
                                        }}
                                        />
                                    )}
                                    {!postsLoading && postsData.PostList.length === 0 && <span className="d-block font-xssss fw-500 p-2 mb-3 text-grey-500 text-center">Não existem publicações</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

//export default GroupSingle;

export default track(
    { page: "Group" },
    //{ dispatchOnMount: contextData => ({ event: "pageLoaded" }) }
)(GroupSingle);